
/* eslint max-lines: off */
import {computed, defineComponent, nextTick, onBeforeUnmount, onMounted, PropType, reactive, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import Toolbar from 'primevue/toolbar';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import OverlayPanel from 'primevue/overlaypanel';
import TieredMenu from 'primevue/tieredmenu';
import {AuthTypes, ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {
	BatchClassClassification,
	BoundingBox,
	CustomValidationActionDto,
	CustomValidationActionResponseDto,
	Document,
	DocumentClassDto,
	DocumentField,
	DocumentFieldValue,
	DocumentSearchRequestDto,
	DocumentTable,
	DocumentTableCell,
	DocumentTableRow,
	DocumentValidationRequestDto,
	ErrorDto,
	FieldTraining, FormTrainingHeadField,
	FormTrainingItemField,
	FormTrainingItemRegionAnchor,
	FormTrainingRegionAnchor,
	PaginationDto,
	SaveValidateAndExportDocumentResponseDto,
	TableColumnTraining,
	ValidationFieldDto
} from '@dex/squeeze-client-ts';
import ValidationFieldSet from '@/apps/squeeze/components/ValidationFieldSet.vue';
import ValidationFieldSetWithoutLayout from '@/apps/squeeze/components/ValidationFieldSetWithoutLayout.vue';
import ValidationFieldSetSkeleton from '@/apps/squeeze/components/ValidationFieldSetSkeleton.vue';
import DialogOptionsConfirm from '@/apps/squeeze/components/DialogOptionsConfirm.vue';
import Viewer from "@/apps/squeeze/components/Viewer.vue";
import {BoundingBoxOld, ViewerClient} from "@/apis/squeeze-viewer/ViewerClient";
import ValidationTable from "@/apps/squeeze/components/ValidationTable.vue";
import Sidebar from 'primevue/sidebar';
import ValidationEmailView from "@/apps/squeeze/views/ValidationEmailView.vue";
import Dialog from 'primevue/dialog';
import DocumentClassChangeForm from "@/apps/squeeze/components/DocumentClassChangeForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import SinglePromise from "@/util/SinglePromise";
import DialogLocked from "@/apps/squeeze/components/DialogLocked.vue";
import HeadTraining from "@/apps/squeeze/views/HeadTraining.vue";
import DocumentSplit from "@/apps/squeeze/components/DocumentSplit.vue";
import PositionTraining from "@/apps/squeeze/views/PositionTraining.vue";
import FormHeadTraining from "@/apps/squeeze/views/FormHeadTraining.vue";
import FormPositionTraining from "@/apps/squeeze/views/FormPositionTraining.vue";
import {RouteLocationRaw, useRoute} from "vue-router";
import {useSqueezeStore} from "@/apps/squeeze/store";
import OcrView from "@/apps/squeeze/views/OcrView.vue";
import LocatorTesting from "@/apps/squeeze/views/LocatorTesting.vue";
import {SqueezeViewer} from "@dex/squeeze-viewer";
import HotKeyList from "@/apps/squeeze/components/HotKeyList.vue";
import Message from 'primevue/message';
import Badge from 'primevue/badge';
import LogView from "@/apps/administration/views/squeeze/log/LogView.vue";
import FileUpload from "@/components/DexFileUpload.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import {GridStackFieldDetails} from "@/apps/administration/views/squeeze/documentclasses/DocumentClassFieldsLayoutView.vue";
import {CustomAction} from "@/components/VerticalNavbar.vue";
import TableBehaviourEnum = DocumentTable.TableBehaviourEnum;
import DocumentTypeEnum = Document.DocumentTypeEnum;

interface DocumentClassFieldGroup {
	id: number | undefined;
	name: string;
	description: string;
	type: number;
	fields: DocumentField[] | undefined;
}

interface SurroundingDocumentIds {
	first?: number;
	random?: number;
	prev?: number;
	self?: number;
	next?: number;
	last?: number;
}

interface FieldInSameLine extends DocumentField {
	sameLineAsPreviousField?: boolean;
	colSize?: string;
	row?: number;
	offsetSize?: number;
}

interface ErrorFields {
	fields?: DocumentField[];
	lengthOfAllErrors?: number;
}

interface FieldGroupDetails {
	fieldGroupId: number;
	fieldLayout: GridStackFieldDetails[];
}

interface FieldGroupLayout {
	fieldGroupId: number;
	fieldLayout: string;
}

export default defineComponent({
	name: "Validation",
	components: {
		HotKeyList,
		OcrView,
		LocatorTesting,
		HeadTraining,
		PositionTraining,
		FormHeadTraining,
		FormPositionTraining,
		ValidationFieldSet,
		ValidationFieldSetWithoutLayout,
		ValidationFieldSetSkeleton,
		Viewer,
		DialogOptionsConfirm,
		ValidationTable,
		Sidebar,
		Splitter,
		SplitterPanel,
		Toolbar,
		ValidationEmailView,
		Dialog,
		DocumentClassChangeForm,
		EntryDialog,
		DialogLocked,
		DocumentSplit,
		OverlayPanel,
		TieredMenu,
		Message,
		Badge,
		LogView,
		FileUpload,
		SqueezeViewer,
		TabView,
		TabPanel,
	},
	props: {
		/** ID of document */
		documentId: {
			type: Number,
			required: true,
		},
		/** ID of document class*/
		documentClassId: {
			type: Number,
			required: true,
		},
		/** Array with the sorting of the table */
		tableSortStart: {
			type: Array as PropType<string[]>,
			default: () => [], // TODO: defaultSort?
		},
		/** Search request for document search filtering */
		searchRequest: {
			type: Object as PropType<DocumentSearchRequestDto>,
			default: () => ({}),
		},
		/** Pagination info for document search */
		pagination: {
			type: Object as PropType<PaginationDto>,
			required: true,
		},
		/** Is upload dialog shown? */
		isUploadShown: {
			type: Boolean,
			default: false,
		},
		searchId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t, locale} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Current Vuex-Store */
		const store = useSqueezeStore()

		const NEW_SQUEEZE_VIEWER = store.state.featureSet.v2Viewer ? store.state.userSettings.viewSettings.v2Viewer : store.state.featureSet.v2Viewer;

		/** Current mode of component */
		const mode = ref<string>("edit");

		/** Is the current mode of component readonly? */
		const isReadOnlyMode = ref<boolean>(false);

		/** Fields of document */
		const documentFields = ref<DocumentField[]>([]);

		/** All document classes */
		const allDocumentClasses = ref<DocumentClassDto[]>([]);

		/** Tables of the document */
		const tables = ref<DocumentTable[]>([]);

		/** Field groups */
		const documentClassFieldsByGroup = ref<DocumentClassFieldGroup[]>([]);

		const serverBaseUrl = (new URL(ClientManager.getInstance().getSqueezeBasePath())).origin; // TODO: What type? ref or reactive?

		/** Indicates end of request */
		const loaded = ref<boolean>(false);
		const show = ref<boolean>(true);

		/** DocumentValidationRequestDto for validation purposes  */
		const validationRequest = reactive<DocumentValidationRequestDto>({fields: []});

		/** Current Viewer-Client */
		const viewerClient = ref<ViewerClient>(); // TODO: ref or reactive?

		/** Currently active field */
		const activeDocumentField = reactive<DocumentField>({});

		/** Currently active table cell */
		const activeTableCell = ref<DocumentTableCell>({});

		/** Currently active table row index */
		const activeTableRowIndex = ref<number|null>(null);

		/** Indicates to block UI components specified */
		const blocked = reactive<any>({
			buttons: {
				save: false,
				suspend: false,
				delete: false,
				extract: false,
				changeDocumentClass: false,
				split: false,
				sendMail: false,
				training: false,
				testing: false,
				attachment: false,
				menu: false,
			},
		});

		/** Currently active table-id */
		const activeTableId = ref<number|null>(null);

		/** Currently active table */
		const activeTable = ref<DocumentTable|null>(null);

		/** Currently active Batch Class ID */
		const batchClassId = ref<number>(0);

		/** Currently active Document-Class */
		const newDocumentClass = ref<number>(0);

		/** Should the table buttons be hidden? */
		const hideTableButtons = ref<boolean>(false);

		/** Confirm dialog configuration - no options = no dropdown in dialog */
		const dialogs = reactive<any>({
			deleteDocument: {
				display: false,
				options: [{label: "", value: ""}],
				locales: {
					header: "Squeeze.Validation.Dialogs.DeleteDocument.Header",
					notice: {
						selectReason: "Squeeze.Validation.Dialogs.DeleteDocument.Notice.SelectReason",
						comment: "Squeeze.Validation.Dialogs.DeleteDocument.Notice.Comment",
					},
					selectPlaceholder: "Squeeze.Validation.Dialogs.DeleteDocument.SelectPlaceholder",
					buttons: {
						confirm: "Squeeze.Validation.Buttons.Delete",
						abort: "Squeeze.Validation.Buttons.Abort",
					},
				},
			},
			suspendDocument: {
				display: false,
				options: [],
				locales: {
					header: "Squeeze.Validation.Dialogs.SuspendDocument.Header",
					notice: {
						selectReason: "Squeeze.Validation.Dialogs.SuspendDocument.Notice.SelectReason",
						comment: "Squeeze.Validation.Dialogs.SuspendDocument.Notice.Comment",
					},
					selectPlaceholder: "Squeeze.Validation.Dialogs.SuspendDocument.SelectPlaceholder",
					buttons: {
						confirm: "Squeeze.Validation.Buttons.Suspend",
						abort: "Squeeze.Validation.Buttons.Abort",
					},
				},
			},
			lockedDocument: {
				display: false,
				locked: false,
			},
			changeDocumentClass: {
				display: false,
				loading: false,
				trainDocument: true,
				classificationClasses: [] as BatchClassClassification[],
			},
			sendMail: {
				display: false,
			},
			errorDocument: {
				display: false,
			},
		});

		/** Options of the toolbar. Due to the translations, those are defined in "mounted()" */
		const toolbarOptions = ref<any[]>([]);

		/** Single Promise for Validation Requests. Only the latest Request will be resolved */
		const singlePromiseValidation = new SinglePromise();

		/** Is the document locked by the current user? */
		const lockedByCurrentUser = ref<boolean>(false);

		/** ID of the user that is currently blocking the document **/
		const lockingUserId = ref<number|null>(null);

		/** Should the Toolbar Button Text be shown? */
		const toolbarButtonText = ref<boolean>(false);

		/** Should the Split-Document be shown? */
		const showSplitDocument = ref<boolean>(false);

		/** Hide Validation-Buttons? */
		const hideValidationButtons = ref<boolean>(false);

		/** Is the Head Training visible? */
		const showHeadTraining = ref<boolean>(false);

		/** Is the Position Training visible? */
		const showPositionTraining = ref<boolean>(false);

		/** Is the Form-based Head Training visible? */
		const showFormHeadTraining = ref<boolean>(false);

		/** Is the Form-based Position Training visible? */
		const showFormPositionTraining = ref<boolean>(false);

		/** Is the Locator test visible? */
		const showLocatorTest = ref<boolean>(false);

		/** Is the Table Splitter visible? */
		const showTableSplitter = ref<boolean>(true);

		/** Is the Table Splitter Gutter visible? */
		const showTableSplitterGutter = ref<boolean>(false);

		/** Is the OCR Results View visible? */
		const showOcrResults = ref<boolean>(false);

		/** Should the drag dialog be shown? */
		const showDragDialog = ref<boolean>(false);

		/** Time of MouseDown Event by Splitter */
		const startTimeByMouseDown = ref<number>(0);

		/** Currently active document group tab */
		const activeGroupTab = ref<number>(0);

		/** List with document fields for training */
		const trainingValues = reactive<FieldTraining>({
			id: 0,
			fieldId: 0,
			documentClassId: 0,
			trainingKeyValue: "",
			keyWordPattern: "",
			valuePattern: "",
			valueRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			keyWordRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			valueIgnoreWhitespace: false,
		});

		const trainingKeyField = ref<DocumentField|null>(null);

		/** Currently active table-field in training */
		const activeFieldTraining = ref<string>('');

		const positionTrainingValues = reactive<TableColumnTraining>({
			id: 0,
			documentClassId: 0,
			tableId: 0,
			columnId: 0,
			trainingKeyValue: "",
			columnRegion: {
				"page": 0,
				"x0": 0,
				"y0": 0,
				"x1": 0,
				"y1": 0,
			},
			valuePattern: "",
		});

		/** Currently active table-field in positionTraining */
		const activePositionTraining = ref<string>('');

		/** Current indicator in form position training */
		const indicatorOfFormTraining = reactive<FormTrainingItemRegionAnchor>({
			id: undefined,
			tableId: undefined,
			description: '',
			keyWordPattern: '',
			keyWordRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			ignoreWhiteSpaces: false,
		});

		/** Currently head field of region in form head training */
		const headFieldOfRegionInFormTraining = reactive<FormTrainingHeadField>({
			id: 0,
			fieldId: 0,
			valuePattern: '',
			valueRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			valueIgnoreWhitespace: false,
			accumulateFoundValues: false,
		});

		/** Currently item field of region in form position training */
		const itemFieldOfRegionInFormTraining = reactive<FormTrainingItemField>({
			id: 0,
			columnId: 0,
			valuePattern: '',
			valueRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			valueIgnoreWhitespace: false,
			accumulateFoundValues: false,
		});

		/** Currently region anchor object of form-based training */
		const regionAnchorOfFormTraining = reactive<FormTrainingRegionAnchor>({
			id: undefined,
			description: '',
			keyWordPattern: '',
			keyWordRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			ignoreWhiteSpaces: true,
		});

		/** Is the document read-only? */
		const readonly = ref<boolean>(false);

		/** Resize observe of left splitter panel */
		const resizeObserver = ref<any>(null);

		/** Current Search-Elements, used for pagination */
		const searchElements = ref<Document[]|null>(null);

		/** Viewer marked regions (V2) */
		const viewerMarkedRegions = ref<BoundingBox[]>([]);

		const viewerShouldObserveResizing = ref<boolean>(false);

		/** Array with all headRefFieldElements */
		const headRefFieldElements = ref<any[]>([]);

		/** Array with all tableRefElements */
		const allTableRefElements = ref<any[]>([]);

		/** Is hotKey-List visible? */
		const showHotKeyList = ref<boolean>(false);

		/** Is Log visible? */
		const showLog = ref<boolean>(false);

		/** Is attachment upload visible? */
		const showUpload = ref<boolean>(false);

		/** Message that shows the number of the currently uploaded documents  */
		const uploadLabel = ref<string>("");

		/** List of all files to be uploaded */
		const files = ref<any[]>([{
			uploadFinished: false,
			loading: false,
			errorText: '',
			error: false,
		}]);

		/** Current Progress of upload */
		const progress = ref<number>(0);

		/** Array with all error messages of fields  */
		const allErrorMessages = reactive<ErrorFields>({
			fields: [],
			lengthOfAllErrors: 0,
		});

		/** Contains the field that the error messages should be currently shown of */
		const currentErrorField = ref<DocumentField|null>(null);

		/** Name of the last  route */
		const lastRoute = ref<any>("");
		const blockBrowseButtons = ref<boolean>(false);

		/** Should the Export Error Message be shown? */
		const showExportError = ref<boolean>(false);
		const exportErrorMessage = ref<string>("");

		/** Field Layout Details */
		const fieldLayoutDetails = ref<FieldGroupDetails[]>([]);

		/** Field Layout Complete */
		const fieldLayoutComplete = reactive<{fieldLayout: FieldGroupDetails[]; fieldLayoutRows: any[]}>({fieldLayout: fieldLayoutDetails.value, fieldLayoutRows: []});

		/** All custom actions */
		const customActions = ref<CustomValidationActionDto[]>([]);

		/** All table custom actions */
		const customTableActions = ref<CustomValidationActionDto[]>([]);

		/** Is confirm dialog for execute an action shown? */
		const showCustomActionDialog = ref<boolean>(false);

		/** Custom Action dialog message */
		const customActionDialogMessage = ref<string>('');

		/** Contains the full current document */
		const currentDocument = ref<Document|null>(null);

		/** Has the validation been triggered once and has the table splitter been set? */
		const tableSplitterSetAfterValidation = ref<boolean>(false);

		/** Current Search request for document search filtering when search request is empty */
		const currentSearchRequest = reactive<DocumentSearchRequestDto>({});

		/** Current workflow context of document */
		const documentWorkflowContext = reactive<any>({});

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Document Class API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Validation API endpoint */
		const validationApi = ClientManager.getInstance().squeeze.validation;

		/** Batch-Class API endpoint */
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;

		/** Bearer token and bearer interval for oAuth authentication */
		const bearerToken = ref<string|null>(null);
		const bearerInterval = ref<number|null>(null);

		if (ClientManager.getInstance().login.activeAuth === AuthTypes.Bearer) {
			bearerToken.value = ClientManager.getInstance().keycloakOptions.token;

			/** Get the current bearer-token from the keycloak-options every 30 seconds. This is done,
			 * because the ClientManager.getInstance().keycloakOptions.token is not watchable and, therefore,
			 * changes of the token are not recognized by the component.
			 * The token is used in the function "getUserAuth"
			 */
			bearerInterval.value = setInterval(() => {
				bearerToken.value = ClientManager.getInstance().keycloakOptions.token;
			}, 30000)
		}

		/** Content of the left splitter panel */
		const leftSplitterPanelContent = ref<any>();

		/** The left splitter panel component */
		const leftSplitterPanel = ref<any>();

		/** Width of the left splitter panel in Pixel */
		const leftSplitterPanelWidth = ref<number>(0);

		/** Component of the squeeze viewer (v2) */
		const SqueezeViewer = ref<any>();

		/**  Component of the validation field set */
		const validationFieldSet = ref<any>({});

		/** Component of the squeeze viewer (v1) */
		const viewer = ref<any>();

		/** Component of the (tiered) menu */
		const menu = ref<any>();

		/** Component of op badge */
		const opBadge = ref<any>();

		/** Component of validation table */
		const tableInValidation = ref<any>({});

		/** Reset all selected rows in current position table */
		const resetSelectedRows = ref<boolean>(false);

		const documentTypeEnum = computed(() => {
			return DocumentTypeEnum;
		});

		const findTableIndex = computed(() => {
			const tableIndex = tables.value.findIndex((table: DocumentTable) => table.id === activeTableId.value);
			if(tableIndex == -1) {
				return null;
			}
			return tables.value[tableIndex];
		});

		const disableBrowseButtons = computed(() => {
			return loaded.value != true;
		});

		const docBaseUrl = computed(() => {
			return `${ClientManager.getInstance().getSqueezeBasePath()}/documents/${props.documentId}`;
		});

		const viewerDrawingMode = computed(() => {
			return activePositionTraining.value === 'columnRegion' ? 'column' : 'rect';
		});

		/** Triggered when hover on button to show the document type
		 * @param documentType
		 */
		const onHoverDocumentType = (documentType: string) => {
			if (documentType === 'xrechnung') {
				return t('Squeeze.DocumentClasses.DocumentTypeXRechnung');
			}

			if (documentType === 'zugferd') {
				return t('Squeeze.DocumentClasses.DocumentTypeZUGFeRD');
			}
		}

		/** Checks the severity of a message */
		const checkErrorSeverity = () => {
			if (!currentErrorField.value) {
				if (allErrorMessages.lengthOfAllErrors === 0) {
					return "success";
				}
			}

			if (allErrorMessages && currentErrorField.value && currentErrorField.value.value!.state?.toLowerCase() === 'forceapproval') {
				return "info"
			}

			if (allErrorMessages && currentErrorField.value && currentErrorField.value.value!.state?.toLowerCase() === 'ok') {
				return "success"
			}

			return "error";
		}

		/**
		 * Toggle error messages
		 * @param event
		 */
		const toggleErrorMessages = (event: any) => {
			const overlayPanel: any = opBadge.value;
			overlayPanel.toggle(event);
		}

		/**
		 * Unlocks the current document
		 * @param ignoreError If true no error will be displayed if unlocking fails
		 */
		const unlockDocument = async (ignoreError = false) => {
			// Only unlock locked documents if it's locked by the current user
			if (!dialogs.lockedDocument.locked && lockedByCurrentUser.value) {
				try {
					await documentApi.unlockDocument(props.documentId);
				} catch (err: unknown) {
					if (!ignoreError) {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err);
					}
				}
			}
		}

		/** Go back to queue list */
		const goBackToQueueList = () => {
			router.push({
				name: 'QueueEntryView',
				params: { stepName: route.query.target as any},
				query: {
					page: route.query.page as any,
				},
			});
		}

		/** Change the View to the Overview */
		const goBackToValidationList = () => {
			// if the component was opened from the QueueEntryView, go back there
			if (route.query.parent && route.query.parent === 'QueueList' && route.query.target) {
				goBackToQueueList();
				return;
			}

			let routeName = "DocumentListValidation";
			const currentSearch = props.searchRequest;
			if (Object.keys(props.searchRequest).length === 0 && Object.keys(currentSearchRequest).length > 0) {
				Object.assign(currentSearch, currentSearchRequest);
			}

			// set default search when validation open via link
			if (Object.keys(currentSearch).length === 0 && documentWorkflowContext.step && documentWorkflowContext.step.toLowerCase() === 'validation') {
				currentSearch.workflowContextFilters = [
					{
						fieldName: "queueStep",
						comp: "eq",
						searchValue: "validation",
						fieldType: "text",
					},
				]
			} else if (Object.keys(currentSearch).length === 0) {
				// When there is no search and the step is not validation, go to DocumentList
				routeName = "DocumentList";
			}

			// If there is a filter and one of the filters is from validation, go to DocumentListValidationWithSearch, otherwise go to DocumentList
			if (Object.keys(currentSearch).length > 0) {
				if (currentSearch.workflowContextFilters?.find(filter => filter.searchValue === "validation" && filter.fieldName === "queueStep")) {
					routeName = "DocumentListValidationWithSearch";
				} else {
					routeName = "DocumentList";
				}
			}

			router.replace({name: routeName, params: {
				documentClassId: route.params.documentClassId,
				searchRequest: JSON.stringify(currentSearch),
				tableSortStart: JSON.stringify(props.tableSortStart),
				pagination: JSON.stringify(props.pagination),
			}});
		}

		/**
		 * Navigates to another document.
		 * Tries to unlock the current document before starting the navigation.
		 */
		const gotoDocument = (id: number, replace: boolean = true) => {
			// Reload data uses the documentId and documentClassId props which are bound to route params
			// When this watcher is called, those props do NOT yet contain the values of the `to` route params.
			// Therefore the data is reloaded on the next tick.
			// Before that happens, unlock the document we are leaving
			if (lockedByCurrentUser.value) {
				unlockDocument(true);
			}

			// Todo: Somehow make sure that if the next route element does not find the document (404), the fallback is the validation list
			const route: RouteLocationRaw = {
				name: "ValidateEntry",
				params: {
					documentClassId: props.documentClassId,
					documentId: id,
					searchRequest: JSON.stringify(props.searchRequest),
					pagination: JSON.stringify(props.pagination),
					tableSortStart: JSON.stringify(props.tableSortStart),
				},
			};

			blocked.buttons.training = false;
			blocked.buttons.testing = false;
			blocked.buttons.attachment = false;

			// Reset Training-Marks
			viewerMarkedRegions.value = [];
			router.push(route);
		}

		/** Browses to the previous document */
		const browseLeft = async () => {
			try {
				const prevDocument = await validationApi.browseValidation(props.documentId, props.documentClassId, "previous", props.searchRequest, props.tableSortStart);

				if (!prevDocument) {
					goBackToValidationList();
					return;
				}

				gotoDocument(prevDocument);
			} catch(response) {
				// Check if response is json, show generic error if not
				if (response.json != null) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), String(response.statusText));
					return;
				}

				const completeResponse = await response.json() as ErrorDto;
				ToastManager.showError(toast, t('Squeeze.General.Error'), String(completeResponse.message));
			}
		}

		/**
		 * Go to next document
		 * @param getNextToValidate
		 */
		const goToNextDocument = async (getNextToValidate: boolean|undefined = undefined) => {
			try {
				const nextDocument = await validationApi.browseValidation(props.documentId, props.documentClassId, "next", props.searchRequest, props.tableSortStart, getNextToValidate);

				if (!nextDocument) {
					//props.pagination.page = 0; //TODO: Check if we need this?!
					goBackToValidationList();
					return;
				}

				dialogs.lockedDocument.display = false;
				gotoDocument(Number(nextDocument));
			} catch(response) {
				// Check if response is json, show generic error if not
				if (response.json != null) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), String(response.statusText));
					return;
				}

				const completeResponse = await response.json() as ErrorDto;
				ToastManager.showError(toast, t('Squeeze.General.Error'), String(completeResponse.message));
			}
		}

		/** Reopens the Validation show (Shows the Buttons, tables and fields, hides everything else) */
		const reopenValidationView = () => {
			showSplitDocument.value = false;
			hideTableButtons.value = false;
			hideValidationButtons.value = false;
			showHeadTraining.value = false;
			showPositionTraining.value = false;
			showFormHeadTraining.value = false;
			showFormPositionTraining.value = false;
			showLocatorTest.value = false;
			loaded.value = true;
			showTableSplitter.value = true;
			showOcrResults.value = false;

			// reset markedRegions in viewer
			viewerMarkedRegions.value = [];
		}

		/** Disable viewer on resizing */
		const disableViewerOnResizing = () => {
			viewerShouldObserveResizing.value = false;

			// Set Pointer Event on Viewer to none, when resize start
			if (NEW_SQUEEZE_VIEWER) {
				SqueezeViewer.value.$el.style.pointerEvents = 'none';
			} else {
				viewer.value.style.pointerEvents = 'none';
			}
		}

		/** Enable viewer after resizing */
		const enableViewerAfterResizing = () => {
			viewerShouldObserveResizing.value = true;

			// Set Pointer Event on Viewer to auto, when resize end
			if (NEW_SQUEEZE_VIEWER) {
				SqueezeViewer.value.$el.style.pointerEvents = 'auto';
			} else {
				viewer.value.style.pointerEvents = 'auto';
			}
		}

		/**
		 * Triggered on Splitter mouse down
		 * @param event
		 */
		const onSplitterMouseDown = (event: MouseEvent) => {
			// Only run if splitter-gutter was clicked!
			const target = event.target as HTMLElement;
			if (!target.matches('.p-splitter-gutter, .p-splitter-gutter *')) {
				return;
			}

			// Set startTime of MouseDown
			startTimeByMouseDown.value = event.timeStamp;

			disableViewerOnResizing();
		}

		/** Set Table Splitter to disabled or enabled */
		const toggleSplitterTable = () => {
			showTableSplitter.value = !showTableSplitter.value;
		}

		/**
		 * Triggered on Splitter mouse up
		 * @param event
		 */
		const onSplitterMouseUp = (event: MouseEvent) => {
			// Only run if splitter-gutter was clicked!
			const target = event.target as HTMLElement;
			if (!target.matches('.p-splitter-gutter, .p-splitter-gutter *')) {
				return;
			}

			// Get Time difference between MouseDown and MouseUp Event
			const timeBetweenMouseDownAndUp = (event.timeStamp - startTimeByMouseDown.value);
			if (!showHeadTraining.value && !showPositionTraining.value && !showFormHeadTraining.value && !showFormPositionTraining.value && !showSplitDocument.value && !showOcrResults.value && !showLocatorTest.value) {
				if (target.matches('.p-splitter-panel-nested .p-splitter-gutter-handle')) {
					// horizontal splitter gutter handle &ndash;&gt; do nothing by click
					enableViewerAfterResizing();
					return;
				} else if (target.matches('.p-splitter-gutter-handle')) {
					// Get Time difference between MouseDown and MouseUp Event
					if (timeBetweenMouseDownAndUp <= 150) {
						toggleSplitterTable();
					}
				}
			}

			enableViewerAfterResizing();
		}

		/** Triggered on splitter resize end */
		const onSplitterResizeEnd = () => {
			leftSplitterPanelWidth.value = leftSplitterPanel.value.$el.clientWidth;
			enableViewerAfterResizing();
		}

		/**
		 * Is triggered when files are selected from the Component
		 * @param event File-Select event
		 */
		const onSelectFiles = (event: any) => {
			files.value = event.files;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		/**
		 * Is triggered when the "clear" button is pressed in the Upload-Component
		 * @param event
		 */
		const clearFiles = (event: any) => {
			uploadLabel.value = t("Squeeze.General.Upload");
		}

		/**
		 * Is triggered when a single file is removed from upload
		 * @param event
		 */
		const removeFile = (event: any) => {
			files.value = event.files;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: File) => {
			// Todo: Once the generated client works, make this function deprecated and use the client function:
			const body = new FormData();
			body.set("file", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/documents/" + props.documentId + "/attachments", {
				method: "POST",
				body: body,
			});

			if (response.status !== 204) {
				const responseJSON = await response.json();
				throw new Error(responseJSON.message);
			}

			return true;
		}

		/**
		 * Uploads the files from the file-uploader
		 * @param event
		 */
		const fileUploader = (event: any) => {
			files.value = event.files;

			progress.value = 0;
			// Calculate progress
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";

			// TODO: Limit the amount of uploads running in parallel (?)
			event.files
				.forEach((file: any, index: number) => {
					if (!file.uploadFinished) { // Files that are already finished shouldn't be uploaded again
						const idx = index;
						files.value[idx].error = false;
						files.value[idx].errorText = "";
						files.value[idx].loading = true;
						files.value = [...files.value];

						manualFileUpload(file)
							.then(() => {
								files.value[idx].uploadFinished = true;
							})
							.catch(err => {
								files.value[idx].error = true;
								files.value[idx].errorText = err.message;
							})
							.finally(() => {
								files.value[idx].loading = false;
								files.value = [...files.value];

								// Calculate progress
								const finished = files.value.filter(file => file.uploadFinished);
								progress.value = Math.round((finished.length * 100) / files.value.length);
								uploadLabel.value = t("Squeeze.General.Upload") + " (" + finished.length + "/" + files.value.length + ")";

								if (NEW_SQUEEZE_VIEWER) {
									SqueezeViewer.value.refreshAttachments();
								} else {
									// Reload iFrame to reload attachments in old viewer
									const iFrame = document.getElementById('SqueezeViewer') as any;
									if (iFrame instanceof HTMLIFrameElement && iFrame.contentWindow != null) {
										// get viewer url for reload
										const viewer = ClientManager.getInstance().viewer;
										const viewerUrl: string = viewer.basePath + '/Viewer.php?aktion=showQueueEntryDetails&sourceSystem=documents&xdocid=' + props.documentId;
										iFrame.contentWindow.location.replace(viewerUrl);
									}
								}
							});
					}
				})
		}

		/** Triggered on drag enter */
		const onDragEnter = () => {
			showDragDialog.value = true;
		}

		/** Triggered on drag leave */
		const onDragLeave = () => {
			showDragDialog.value = false;
		}

		/**
		 * Triggered on drop
		 * @param event
		 */
		const onDrop = (event: any) => {
			event.stopPropagation();
			event.preventDefault();
			showDragDialog.value = false;

			const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
			showUpload.value = true;
			nextTick(() => {
				const uploadFiles: any[] = [];
				for (const file of files) {
					file.uploadFinished = false;
					file.error = false;
					file.errorText = "";
					file.loading = false;
					uploadFiles.push(file);
				}
				files.value = uploadFiles;
				fileUploader({files: uploadFiles});
			})
		}

		/**
		 * Triggered when a Table Cell is focused
		 * @param fieldName
		 */
		const onFocusFieldTraining = (fieldName: string) => {
			activeFieldTraining.value = fieldName;
		}

		/**
		 * Triggered when the position training values are reset
		 * @param values
		 */
		const onChangePositionTrainingValues = (values: TableColumnTraining) => {
			Object.assign(positionTrainingValues, values);
		}

		/**
		 * Triggered when a field of position training is focused
		 * @param fieldName
		 */
		const onFocusFieldOfPositionTraining = (fieldName: string) => {
			activePositionTraining.value = fieldName;
			if (activePositionTraining.value === 'columnRegion') {
				viewerClient.value?.activateColumnMarking();
			} else {
				viewerClient.value?.deactivateColumnMarking();
			}
		}

		/**
		 * Triggered when a Table Cell is focused
		 * @param markRegions
		 */
		const onMarkRegion = (markRegions: any) => {
			if (Array.isArray(markRegions)) {
				viewerMarkedRegions.value = markRegions as BoundingBox[];
				viewerClient.value?.markRegions(markRegions);
			} else {
				// This is a special case for the position training
				markRegions.page = 0;
				viewerMarkedRegions.value = [markRegions] as BoundingBox[];
				viewerClient.value?.markRegion(markRegions);
			}
		}

		/**
		 * Triggered when a Table Row is clicked
		 * @param markLine
		 */
		const onMarkLines = (markLine: any) => {
			viewerMarkedRegions.value = [markLine.data.boundingBox];
			viewerClient.value?.markRegion(markLine.data.boundingBox);
		}

		/**
		 * Triggered when a Table Cell is clicked
		 * @param markWord
		 */
		const onMarkWord = (markWord: any) => {
			viewerMarkedRegions.value = [markWord.data.boundingBox];
			viewerClient.value?.markRegion(markWord.data.boundingBox);
		}

		/**
		 * Triggered when a page on the Document Split Component is clicked
		 * @param page Page that is triggered
		 */
		const onSplitImageClick = (page: number) => {
			// reset markedRegions in viewer
			viewerMarkedRegions.value = [];

			if (NEW_SQUEEZE_VIEWER) {
				viewerMarkedRegions.value = [{page: page, x0: 1, x1: 1, y0: 1, y1: 1}];
			} else {
				viewerClient.value?.goToPage(page);
			}
		}

		/**
		 * Browses to the next document
		 * @param getNextToValidate
		 */
		const getNextDocument = async (getNextToValidate: boolean|undefined = undefined) => {
			try {
				const nextDocument = await validationApi.browseValidation(props.documentId, props.documentClassId, "next", props.searchRequest, props.tableSortStart, getNextToValidate);
				if (!nextDocument) {
					return;
				}
				return (Number(nextDocument))
			} catch(response) {
				// Check if response is json, show generic error if not
				if (response.json != null) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), String(response.statusText));
					return;
				}

				const completeResponse = await response.json() as ErrorDto;
				ToastManager.showError(toast, t('Squeeze.General.Error'), String(completeResponse.message));
			}
		}

		/**
		 * This function handles the nextDocument id that is returned by {@link getNextDocument}. If next document id is
		 * undefined this functions routes the user back to validation list, otherwise to the next document.
		 * @param nextDocumentId
		 */
		const handleNextDocumentId = (nextDocumentId: number | undefined) => {
			if (nextDocumentId) {
				// check if the split document view is shown, so that deactivate view of the split document
				if (showSplitDocument.value) {
					hideValidationButtons.value = false;
					showSplitDocument.value = false;
				}
				gotoDocument(nextDocumentId);
			} else {
				//props.pagination.page = 0; //TODO: Check if we need this?!
				goBackToValidationList();
				return;
			}
		}

		/** Is triggered when there are no more pages to split */
		const onEmptyList = async () => {
			const nextDocumentId = await getNextDocument(true);
			documentApi.deleteDocumentById(props.documentId, "SplitDone")
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Validation.Dialogs.SplitDocument.AllPagesSplit'));
					handleNextDocumentId(nextDocumentId);
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/**
		 * Triggered when a Head Field is focused
		 * @param field
		 */
		const markFieldByRegion = (field: DocumentField) => {
			currentErrorField.value = field;
			activeTableCell.value.columnId = 0;  // invalidate active Table-Cell field
			Object.assign(activeDocumentField, field);

			if (field.value && field.value.boundingBox) {
				viewerMarkedRegions.value = [field.value.boundingBox];
			}
			if (field.value && field.value.boundingBox && viewerClient.value) {
				viewerClient.value.markRegion(field.value.boundingBox);
			}
		}

		/**
		 * Formats an amount to locale string (Formats are only defined in the backend)
		 * @param value
		 */
		const formatAmount = (value: string) => {
			return value;
			/*value = value.replace(/[^0-9.,-]/g, "");

			if(value.indexOf(",") != -1) {
				value = value.replaceAll(".", "").replace(",", ".");
			}

			if(value.length > 0) {
				return parseFloat(value).toLocaleString(i18n.global.locale.toLowerCase() + '-' + i18n.global.locale.toUpperCase(), {minimumFractionDigits: 2});
			} else {
				return "0,00";
			}*/
		}

		/** Build validation request */
		const buildValidationRequest = () => {
			Object.assign(validationRequest, {
				fields: [],
				tables: tables.value,
			});

			/**
			 * FIXME: Refactor ValidateFieldDto as well as DocumentField to match required params.
			 * Consider a low payload by passing necessary information only:
			 * - id
			 * - value
			 * - type
			 * Consider adding validation results for each field:
			 * - errortext
			 * - state
			 */
			documentFields.value.forEach(field => {
				let id = field.id;
				if (!id) id = 0;
				let fieldValue = field.value?.value;
				if (!fieldValue) fieldValue = "";
				if (field.dataType?.toLowerCase() === "amount") fieldValue = "" + fieldValue;
				let fieldState = field.state;
				if (!fieldState) fieldState = "";

				const validationField = {
					id: id,
					name: field.name,
					mandatory: field.mandatory,
					readonly: field.readonly,
					hidden: field.hidden,
					forceValidation: field.forceValidation,
					state: fieldState,
					errorText: "",
					value: {
						value: fieldValue,
						boundingBox: field.value?.boundingBox,
					},
				}
				validationRequest.fields?.push(validationField);
			});
		}

		/**
		 * handle validation field response
		 * @param validationFields
		 */
		const handleValidationFieldResponse = (validationFields: ValidationFieldDto[]) => {
			let isValid = true;

			validationFields.forEach((field: ValidationFieldDto)  => {
				const index = documentFields.value.findIndex((documentField) => {
					return documentField.id === field.id;
				});

				if (field.state === "ERROR" || field.state === "FORCEAPPROVAL") {
					isValid = false;
				}

				const uiField = documentFields.value[index];
				switch (uiField.dataType?.toLowerCase()) {
				case "amount":
					uiField.value!.value = formatAmount(field.value.value as any);
					break;
				default:
					uiField.value!.value = field.value.value;
					break;
				}

				uiField.value!.boundingBox = field.value.boundingBox;
				if (field.mandatory != null) uiField.mandatory = field.mandatory;
				if (field.readonly != null) uiField.readonly = field.readonly;
				if (field.hidden != null) uiField.hidden = field.hidden;
				if (field.forceValidation != null) uiField.forceValidation = field.forceValidation;

				if (field.errorCode === -1 || field.errorCode == null) {
					// Unknown error code or no error code set: use plain errorText
					uiField.value!.errorText = field.errorText;
				} else {
					uiField.value!.errorText = t("Squeeze.Validation.ErrorCode." + field.errorCode);
				}

				uiField.value!.state = field.state;
			});

			return isValid;
		}

		/**
		 * Handle validation table response
		 * @param validationTables
		 */
		const handleValidationTableResponse = (validationTables: DocumentTable[]) => {
			let isValidTable = true;

			// validation tableRow cell state
			validationTables.forEach((table) => {
				table.rows?.forEach((row) => {
					row.cells?.forEach((cell) => {
						if (!isValidTable) return false;
						isValidTable = cell.state === "OK" ||cell.state === "FORCEAPPROVAL";
					})
				})
			})

			/* validation table states
			validationTables.forEach((table) => {
				if (!isValidTable) return false;
				isValidTable = table.state === "OK" || table.state === "FORCEAPPROVAL";
			})
			*/

			return isValidTable;
		}

		/** Get all error messages of fields */
		const getAllErrorMessages = () => {
			const allErrorMessagesHead = documentFields.value.filter((field: DocumentField) => (field.value?.state?.toLowerCase() === 'error' || field.value?.state?.toLowerCase() === 'forceapproval'));
			const allErrorMessagesPos = tables.value.filter(table => (table.state?.toLowerCase() === 'error' || table.state?.toLowerCase() === 'forceapproval'))
				.map(table => {
					const field: DocumentField = {
						description: table.description,
						value: {
							errorText: t('Squeeze.Validation.General.ErrorLines') + " " + table.errorText,
						},
					}
					return field;
				});
			allErrorMessages.lengthOfAllErrors = allErrorMessagesHead.length + allErrorMessagesPos.length;

			// Get first error field if there is none selected
			if (!currentErrorField.value) {
				const firstErrorField = documentFields.value.find((field: DocumentField) => (field.value?.state?.toLowerCase() === 'error' || field.value?.state?.toLowerCase() === 'forceapproval'));
				if (firstErrorField) {
					currentErrorField.value = firstErrorField;
				}
			}

			allErrorMessages.fields = allErrorMessagesHead.concat(allErrorMessagesPos);
		}

		/**
		 * Check table behaviour
		 * @param table
		 */
		const checkTableBehaviour = (table: DocumentTable) => {
			switch(table.tableBehaviour) {
			case TableBehaviourEnum.Open: {
				showTableSplitter.value = true;
				break;
			}
			case TableBehaviourEnum.Closed: {
				showTableSplitter.value = false;
				break;
			}
			default: {
				showTableSplitter.value = table.rows?.length !== 0;
				break;
			}
			}
		}

		/** Gets the Object for the currently active table */
		const getActiveTable = () => {
			const table = tables.value.find(table => table.id === activeTableId.value)

			Object.assign(activeTable, undefined);
			showTableSplitterGutter.value = false;

			if (table) {
				activeTable.value = table;
				showTableSplitterGutter.value = true;
			}
		}

		/** Toggle result list */
		const toggleResultList = () => {
			disableViewerOnResizing();
			nextTick(() => {
				enableViewerAfterResizing();
			})
		}

		/** Validate document fields (trigger by field value change and at save event) */
		const validateDocument = async () => {
			let isValidHead = true;
			let isValidTable = true;
			blocked.buttons.save = true;

			if (readonly.value) {
				return (isValidHead && isValidTable);
			}

			buildValidationRequest();

			try {
				const validationResponse = await singlePromiseValidation.execute(documentApi.validateDocument(props.documentId, validationRequest));
				const validationFields = validationResponse.fields!;
				tables.value = validationResponse.tables;

				isValidHead = handleValidationFieldResponse(validationFields);
				isValidTable = handleValidationTableResponse(tables.value);

				if (isValidHead && isValidTable && !isReadOnlyMode.value) {
					blocked.buttons.save = false;
				}

				getAllErrorMessages();

				// If there is no table splitter, check if the active table has lines. If it has, always show
				if (!tableSplitterSetAfterValidation.value) {
					const table = tables.value.find(currTable => currTable.id === activeTableId.value);
					if (table) {
						checkTableBehaviour(table);
						getActiveTable();
						toggleResultList();
					}

					tableSplitterSetAfterValidation.value = true;
				}
			} catch (reason) {
				if (reason.json == null) {
					ToastManager.showError(toast, t('Squeeze.Validation.Error'), String(reason));
				}
				else {
					const completeResponse = await reason.json() as ErrorDto;
					ToastManager.showError(toast, t('Squeeze.Validation.Error'), t('Squeeze.Validation.Dialogs.SaveDocument.Error') + "\n" + completeResponse.message);
				}

				isValidHead = isValidTable = false;
			}

			return (isValidHead && isValidTable);
		}

		/**
		 * Set the Viewer BoundingBox values in values
		 * @param values
		 * @param boundingBox
		 */
		const mapBoundingBoxDtoToViewerBbox = (values: BoundingBox, boundingBox: BoundingBoxOld) => {
			values.x0 = boundingBox.left;
			values.y0 = boundingBox.top;
			values.x1 = boundingBox.right;
			values.y1 = boundingBox.bottom;
		}

		/**
		 * Return index of document field
		 * @param name
		 */
		const getDocumentFieldIndex = (name: string) => {
			return documentFields.value.findIndex(field => field.name == name);
		}

		/**
		 * On viewer area selected
		 * @param payload
		 */
		const onViewerAreaSelected = async (payload: { words: string[]; boundingBox: BoundingBox }) => {
			// Only used by new Squeeze-Viewer
			const { words, boundingBox } = payload;

			// console.log('onViewerAreaSelected', payload);

			// if (words && words.length) {
			// 	const str = encodeURIComponent(words.join(' '));
			// 	const url = ClientManager.getInstance().getSqueezeBasePath() + `/util/rpc/formatString?str=${str}`;
			// 	const res = await fetch(url, {
			// 		method: 'GET',
			// 		headers: { Authorization: this.getUserAuth() },
			// 	});
			// 	const formatted = await res.json();

			// 	console.log('formatted response in onViewerAreaSelected', formatted);
			// }

			// if readOnlyMode true, then prevent to get the value of draw area in viewer
			if (isReadOnlyMode.value && (!showHeadTraining.value && !showPositionTraining.value && !showFormHeadTraining.value && !showFormPositionTraining.value)) {
				return
			}

			const value = words.join(' ');
			const boundingBoxOld: BoundingBoxOld = {
				bottom: boundingBox.y1 || 0,
				left: boundingBox.x0 || 0,
				right: boundingBox.x1 || 0,
				top: boundingBox.y0 || 0,
				page: String(boundingBox.page || 0),
			};

			viewerMarkedRegions.value = [boundingBox];

			if (showHeadTraining.value) {
				if (activeFieldTraining.value === 'keyWordPattern') {
					if (value === trainingValues.keyWordPattern) {
						trainingValues.keyWordPattern = '';
					}
					await nextTick(() => trainingValues.keyWordPattern = value);
					if (trainingValues.keyWordRegion) {
						mapBoundingBoxDtoToViewerBbox(trainingValues.keyWordRegion, boundingBoxOld);
					}
				}
				if (activeFieldTraining.value === 'valuePattern') {
					if (value === trainingValues.valuePattern) {
						trainingValues.valuePattern = '';
					}
					await nextTick(() => trainingValues.valuePattern = value);
					if (trainingValues.valueRegion) {
						mapBoundingBoxDtoToViewerBbox(trainingValues.valueRegion, boundingBoxOld);
					}
				}
			} else if (showPositionTraining.value) {
				if (activePositionTraining.value === 'valuePattern') {
					if (value === positionTrainingValues.valuePattern) {
						positionTrainingValues.valuePattern = '';
					}
					await nextTick(() => positionTrainingValues.valuePattern = value);
				}
				if (activePositionTraining.value === 'columnRegion') {
					if (positionTrainingValues.columnRegion) {
						mapBoundingBoxDtoToViewerBbox(positionTrainingValues.columnRegion, boundingBoxOld);
					}
				}
			} else if (showFormHeadTraining.value || showFormPositionTraining.value) {
				if (activeFieldTraining.value === 'indicatorKeyWordPattern') {
					if (value === indicatorOfFormTraining.keyWordPattern) {
						indicatorOfFormTraining.keyWordPattern = '';
					}
					await nextTick(() => indicatorOfFormTraining.keyWordPattern = value);
					if (indicatorOfFormTraining.keyWordRegion) {
						mapBoundingBoxDtoToViewerBbox(indicatorOfFormTraining.keyWordRegion, boundingBoxOld);
						indicatorOfFormTraining.keyWordRegion.page = Number(boundingBoxOld.page);
					}
				}

				if (activeFieldTraining.value === 'headFieldValuePattern') {
					if (value === headFieldOfRegionInFormTraining.valuePattern) {
						headFieldOfRegionInFormTraining.valuePattern = '';
					}
					await nextTick(() => headFieldOfRegionInFormTraining.valuePattern = value);
					if (headFieldOfRegionInFormTraining.valueRegion) {
						mapBoundingBoxDtoToViewerBbox(headFieldOfRegionInFormTraining.valueRegion, boundingBoxOld);
						headFieldOfRegionInFormTraining.valueRegion.page = Number(boundingBoxOld.page);
					}
				}
				if (activeFieldTraining.value === 'itemFieldValuePattern') {
					if (value === itemFieldOfRegionInFormTraining.valuePattern) {
						itemFieldOfRegionInFormTraining.valuePattern = '';
					}
					await nextTick(() => itemFieldOfRegionInFormTraining.valuePattern = value);
					if (itemFieldOfRegionInFormTraining.valueRegion) {
						mapBoundingBoxDtoToViewerBbox(itemFieldOfRegionInFormTraining.valueRegion, boundingBoxOld);
						itemFieldOfRegionInFormTraining.valueRegion.page = Number(boundingBoxOld.page);
					}
				}
				if (activeFieldTraining.value === 'regionAnchorKeyWordPattern') {
					if (value === regionAnchorOfFormTraining.keyWordPattern) {
						regionAnchorOfFormTraining.keyWordPattern = '';
					}
					await nextTick(() => regionAnchorOfFormTraining.keyWordPattern = value);
					if (regionAnchorOfFormTraining.keyWordRegion) {
						mapBoundingBoxDtoToViewerBbox(regionAnchorOfFormTraining.keyWordRegion, boundingBoxOld);
						regionAnchorOfFormTraining.keyWordRegion.page = Number(boundingBoxOld.page);
					}
				}
			} else {
				if (activeDocumentField && activeDocumentField.id) {
					// FIXME: Is there a better way to assert non-null?
					const fieldIndex = getDocumentFieldIndex(activeDocumentField.name as any);
					const documentField = documentFields.value[fieldIndex];

					if (documentField && !activeDocumentField.readonly) {
						const documentFieldValue = documentField.value;
						if (documentFieldValue) {
							if (documentField.dataType?.toLowerCase() == "amount") {
								documentFieldValue.value = formatAmount(value);
							} else {
								documentFieldValue.value = value;
							}

							if (documentField && documentField.lookup && documentField.lookup.active && !documentField.lookup.allowCustomValues) {
								const eventSent = {
									query: documentFieldValue.value,
								}

								if (validationFieldSet.value[documentField.fieldGroupId as any]) {
									await validationFieldSet.value[documentField.fieldGroupId as any].checkAutocompleteValues(eventSent as any, documentField as any, documentFieldValue.value);
								}
							}

							documentFieldValue.boundingBox = boundingBox;
							await validateDocument();
						}
					}
				}
				else if (activeTable.value && activeTableCell.value.columnId) {
					const column = (activeTable.value.columns || []).find(column => column.name === activeTableCell!.value!.columnName);

					if (column && column.dataType && !column.readonly) {
						if (column.dataType.toLowerCase() == "amount") {
							activeTableCell.value.value = formatAmount(value);
						} else {
							activeTableCell.value.value = value;
						}

						if (column.lookup && column.lookup.active && !column.lookup.allowCustomValues) {
							const eventSent = {
								query: activeTableCell.value.value,
							}

							if (tableInValidation.value[activeTableId.value as any]) {
								await tableInValidation.value[activeTableId.value as any].checkAutocompleteValues(eventSent as any, column as any, activeTableCell.value, activeTableCell.value.value, activeTableRowIndex.value);
							}
						}

						await validateDocument();
					}
				}
			}
		}

		/**
		 * Triggered when the table structure has been changed (i.e. the order has been changed or a row was deleted or added)
		 * @param tableSet
		 * @param skipValidateOfDocument
		 */
		const changeTableData = async (tableSet: any, skipValidateOfDocument: boolean = false) => {
			const tableIndex = tables.value.findIndex((table: DocumentTable) => table.id === activeTableId.value);
			if(tableIndex == -1) {
				return null;
			}

			tables.value[tableIndex] = tableSet;
			if (!skipValidateOfDocument) {
				await validateDocument();
			}
		}

		/**
		 * Triggered when a Table Cell is focused
		 * @param cell
		 * @param row
		 * @param index
		 * @param field
		 */
		const onFocusTableCell = (cell: DocumentTableCell, row: DocumentTableRow, index: number, field: DocumentField) => {
			currentErrorField.value = field;
			activeTableCell.value = cell;
			activeTableRowIndex.value = index;
			activeDocumentField.id = 0; // invalidate activeDocument field

			if (row.value && row.value.boundingBox) {
				viewerMarkedRegions.value = [row.value.boundingBox];
			}
			if (row.value && row.value.boundingBox && viewerClient.value) {
				viewerClient.value.markRegion(row.value.boundingBox);
			}
		}

		/** Triggered when nextTableCell is undefined - focus last headField */
		const focusLastHeadField = () => {
			const prevGroup = headRefFieldElements.value.slice(-1)[0];
			const prevElement = prevGroup.slice(-1)[0];
			const prevGroupIndex = headRefFieldElements.value.findIndex((group: any) => group === prevGroup);
			const prevElementIndex = prevGroup.findIndex((field: any) => field === prevElement);

			headRefFieldElements.value[prevGroupIndex][prevElementIndex].element.$el.focus();
		}

		/**
		 * Get all tableRefCells of a table
		 * @param tableRefCells
		 */
		const allTableRefCells = (tableRefCells: any) => {
			allTableRefElements.value.push(tableRefCells);
		}

		/** Download the current Attachment in Validation (as ZIP file) */
		const downloadAttachment = () => {
			const downloadURL = ClientManager.getInstance().buildDocumentDownloadUrl(props.documentId);
			window.open(downloadURL);
		}

		/** Init the dialog options */
		const initDialogOptions = () => {
			dialogs.deleteDocument.options = [
				{
					label: t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Duplicate'),
					value: "duplicate",
				},
				{
					label: t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Dunning'),
					value: "dunning",
				},
				{
					label: t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Advertisement'),
					value: "advertisement",
				},
				{
					label: t('Squeeze.Validation.Dialogs.DeleteDocument.Reasons.Misc'),
					value: "misc",
				},
			];
		}

		/**
		 * Shows selected dialog and closes every other dialog
		 * @param selectedDialog
		 */
		const showDialog = (selectedDialog: string) => {
			for (const [dialog, configuration] of Object.entries(dialogs)) {
				(configuration as any).display = (dialog.toLowerCase() === selectedDialog.toLowerCase());
			}
		}

		/** Extracts the Document again */
		const extractDocument = async () => {
			loaded.value = false;
			const nextDocumentId = await getNextDocument(true);
			documentApi.extractDocument(props.documentId)
				.then(() => {
					loaded.value = true;
					handleNextDocumentId(nextDocumentId);
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					loaded.value = true;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/**
		 * Execute the custom action in validation
		 * @param actionId
		 */
		const executeCustomValidationAction = (actionId: string) => {
			resetSelectedRows.value = false;
			buildValidationRequest();
			validationApi.executeCustomValidationAction(actionId, props.documentId, validationRequest)
				.then((actionResponse: CustomValidationActionResponseDto) => {
					if (actionResponse.errorMessage) {
						ToastManager.showError(toast, t('Squeeze.General.Error'), actionResponse.errorMessage);
						return;
					}

					// show document which is in return value
					switch(actionResponse.returnType) {
					case 'showDocument': {
						if (!actionResponse.returnValue.documentId) {
							return;
						}
						gotoDocument(Number(actionResponse.returnValue.documentId));
						break;
					}
					case 'stay': {
						if (actionResponse.fields) {
							handleValidationFieldResponse(actionResponse.fields);
						}
						if (actionResponse.tables) {
							resetSelectedRows.value = true;
							tables.value = actionResponse.tables;
						}
						validateDocument();
						break;
					}
					}

					// set returnValue message
					customActionDialogMessage.value  = actionResponse.returnValue.message;
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				})
				.finally(() => {
					// show dialog when the custom action has a message
					if (customActionDialogMessage.value) {
						showCustomActionDialog.value = true;
					}
				})
		}

		/** Init the toolbar options. Otherwise, the changes of the disabled attribute might not be realized */
		const initToolbarOptions = () => {
			toolbarOptions.value = [
				{
					label: t('Squeeze.Validation.Extraction.RecreateResult'),
					icon: 'mdi mdi-file-restore-outline',
					disabled: blocked.buttons.extract,
					command: () => {
						extractDocument();
					},
				},
				{
					label: t('Squeeze.Validation.Dialogs.ChangeDocumentClass.Change'),
					icon: 'mdi mdi-file-document-edit-outline',
					disabled: blocked.buttons.changeDocumentClass,
					command: () => {
						showDialog("changeDocumentClass");
					},
				},
				{
					label: t('Squeeze.Validation.Email.SendMail'),
					icon: 'mdi mdi-email-send-outline',
					disabled: false,
					command: () => {
						showDialog("sendMail");
					},
				},
				{
					label: t('Squeeze.Validation.Dialogs.SplitDocument.SplitCommand'),
					icon: 'mdi mdi-file-compare',
					disabled: blocked.buttons.split,
					command: () => {
						showSplitDocument.value = true;
						loaded.value = false;
						hideTableButtons.value = true;
						hideValidationButtons.value = true;
						showTableSplitter.value = false;
					},
				},
				{
					label: t('Squeeze.Training.Trainings'),
					icon: 'mdi mdi-folder-text-outline',
					items: [
						{
							label: t('Squeeze.Training.FieldTraining'),
							icon: 'mdi mdi-form-textbox',
							disabled: blocked.buttons.training,
							command: () => {
								showHeadTraining.value = true;
								loaded.value = false;
								hideTableButtons.value = true;
								hideValidationButtons.value = true;
								showTableSplitter.value = false;
								showPositionTraining.value = false;
								showFormHeadTraining.value = false;
								showFormPositionTraining.value = false;
								showOcrResults.value = false;
								showLocatorTest.value = false;

								// reset markedRegions in viewer
								viewerMarkedRegions.value = [];
							},
						},
						{
							label: t('Squeeze.Training.PositionTraining'),
							icon: 'mdi mdi-table',
							disabled: blocked.buttons.training,
							command: () => {
								showPositionTraining.value = true;
								loaded.value = false;
								hideTableButtons.value = true;
								hideValidationButtons.value = true;
								showTableSplitter.value = false;
								showHeadTraining.value = false;
								showFormHeadTraining.value = false;
								showFormPositionTraining.value = false;
								showOcrResults.value = false;
								showLocatorTest.value = false;
							},
						},
					],
				},
				{
					label: t('Squeeze.Attachments.ZIPDownlaod'),
					icon: 'mdi mdi-folder-download-outline',
					command: () => {
						downloadAttachment();
					},
				},
				{
					label: t('Squeeze.DocumentClasses.OCRResults'),
					icon: 'mdi mdi-ocr',
					command: () => {
						showOcrResults.value = true;
						loaded.value = false;
						hideTableButtons.value = true;
						hideValidationButtons.value = true;
						showTableSplitter.value = false;
						showHeadTraining.value = false;
						showPositionTraining.value = false;
						showFormHeadTraining.value = false;
						showFormPositionTraining.value = false;
						showLocatorTest.value = false;

						// reset markedRegions in viewer
						viewerMarkedRegions.value = [];
					},
				},
				{
					label: t('Squeeze.Locators.Test'),
					icon: 'mdi mdi-folder-text-outline',
					disabled: blocked.buttons.testing,
					command: () => {
						showLocatorTest.value = true;
						loaded.value = false;
						hideTableButtons.value = true;
						hideValidationButtons.value = true;
						showTableSplitter.value = false;
						showHeadTraining.value = false;
						showPositionTraining.value = false;
						showFormHeadTraining.value = false;
						showFormPositionTraining.value = false;
						showOcrResults.value = false;

						// reset markedRegions in viewer
						viewerMarkedRegions.value = [];
					},
				},
				{
					label: t('Squeeze.Validation.General.AddAttachment'),
					icon: 'mdi mdi-cloud-upload-outline',
					disabled: blocked.buttons.attachment,
					command: () => {
						showUpload.value = true;
					},
				},
				{
					label: t('Squeeze.Validation.Help'),
					icon: 'mdi mdi-comment-question-outline',
					command: () => {
						showHotKeyList.value = true;
					},
				},
			]

			if (store.state.scopes.sqzAdmin && store.state.featureSet.documentLog) {
				toolbarOptions.value.push(
					{
						label: t('Squeeze.Validation.Log.Log'),
						icon: 'mdi mdi-script-text-outline',
						command: () => {
							showLog.value = true;
						},
					}
				)
			}

			if (store.state.featureSet.formTraining) {
				const indexOfTrainingMenu = toolbarOptions.value.findIndex(option => option.label === t('Squeeze.Training.Trainings'));
				if (indexOfTrainingMenu) {
					toolbarOptions.value[indexOfTrainingMenu].items.push(
						{
							label: t('Squeeze.Training.FormBasedHeadTraining'),
							icon: 'mdi mdi-form-textarea',
							disabled: blocked.buttons.training,
							command: () => {
								showFormHeadTraining.value = true;
								loaded.value = false;
								hideTableButtons.value = true;
								hideValidationButtons.value = true;
								showTableSplitter.value = false;
								showHeadTraining.value = false;
								showPositionTraining.value = false;
								showFormPositionTraining.value = false;
								showOcrResults.value = false;
								showLocatorTest.value = false;

								// reset markedRegions in viewer
								viewerMarkedRegions.value = [];
							},
						},
						{
							label: t('Squeeze.Training.FormBasedPositionTraining'),
							icon: 'mdi mdi-file-table-outline',
							disabled: blocked.buttons.training,
							command: () => {
								showFormPositionTraining.value = true;
								loaded.value = false;
								hideTableButtons.value = true;
								hideValidationButtons.value = true;
								showTableSplitter.value = false;
								showHeadTraining.value = false;
								showPositionTraining.value = false;
								showFormHeadTraining.value = false;
								showOcrResults.value = false;
								showLocatorTest.value = false;

								// reset markedRegions in viewer
								viewerMarkedRegions.value = [];
							},
						}
					)
				}
			}

			if (customActions.value && customActions.value.length) {
				// get custom action as menuItem object
				const allActions: CustomAction[] = [];
				customActions.value.map((action: CustomAction) => {
					allActions.push({
						label: action.description,
						icon: 'mdi mdi-square-small',
						command: () => { executeCustomValidationAction(action.id as string) },
					})
				})

				toolbarOptions.value.push(
					{
						label: t('Squeeze.CustomAction.Actions'),
						items: allActions,
					}
				)
			}
		}

		/** Set ToolbarOptions in read only mode, when headTraining|positionTraining|ocrResults|locatorTesting is shown */
		const setToolbarOptionsInReadOnlyMode = () => {
			if (hideValidationButtons.value) {
				blocked.buttons.extract = true;
				blocked.buttons.changeDocumentClass = true;
				blocked.buttons.split = true;
			} else {
				blocked.buttons.extract = false;
				blocked.buttons.changeDocumentClass = false;
				blocked.buttons.split = !store.state.featureSet.uiAllowDocumentSplit;
			}

			if (readonly.value) {
				blocked.buttons.extract = true;
				blocked.buttons.changeDocumentClass = true;
				blocked.buttons.split = true;
			}

			initToolbarOptions();
		}

		/**
		 * Is clicking the options button, then show toolbarOptions in overlayPanel
		 * @param event
		 */
		const toggleToolBarOptions = (event: any) => {
			setToolbarOptionsInReadOnlyMode();
			const overlayPanel: any = menu.value;
			overlayPanel.toggle(event);
		}

		/** Show or disabled toolbarButton-Text */
		const showToolbarButtonText = async () => {
			disableViewerOnResizing();
			/** Check the window size at first load to set the toolbarButtonText to display none */
			if (window.innerWidth <= 1835) {
				toolbarButtonText.value = false;
			}
			toolbarButtonText.value = window.matchMedia('(min-width: 1880px)').matches;

			// disable and enable the viewer by resizing, so that the splitter is dynamic
			await nextTick();
			enableViewerAfterResizing();
		}

		/** Sets the whole document to read only */
		const setReadOnlyMode = () => {
			isReadOnlyMode.value = true;
			hideTableButtons.value = true;

			blocked.buttons.save = true;
			blocked.buttons.delete = true;
			blocked.buttons.suspend = true;

			blocked.buttons.training = true;
			blocked.buttons.testing = true;
			blocked.buttons.attachment = true;

			// Allow training / locator testing even for processed documents
			if (currentDocument.value!.workflowContext!.step === 'Backup') {
				blocked.buttons.training = false;
				blocked.buttons.testing = false;
			} else if (currentDocument.value!.workflowContext!.step === 'Classification' || currentDocument.value!.workflowContext!.step === 'Extraction') {
				// Enable testing of locators after successful completion of the OCR step
				blocked.buttons.testing = false;
			}

			initToolbarOptions();
			showToolbarButtonText();
		}

		/**
		 * Triggered if the drag dialog is shown
		 * @param event
		 */
		const triggerShowDragDialog = (event: any) => {
			event.preventDefault();

			// check first item of dataTransfer
			const item = event.dataTransfer.items[0];

			if (!item) {
				return;
			}

			if (item.kind === 'string') {
				return
			}

			if (!hideValidationButtons.value && !showUpload.value && !props.isUploadShown) {
				showDragDialog.value = true;
			}
		}

		/** Lock the current document */
		const lockDocument = () => {
			documentApi.lockDocument(props.documentId)
				.then(() => {
					lockedByCurrentUser.value = true;
				})
				.catch(response => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Unload the current document */
		const onUnload = () => {
			unlockDocument(true);

			// set search request in local storage
			const currentSearchRequestInLocalStorage = localStorage.getItem('searchRequest');
			if (Object.keys(props.searchRequest) && !currentSearchRequestInLocalStorage) {
				localStorage.setItem('searchRequest', JSON.stringify(props.searchRequest));
			}
		}

		/** Get all document classes */
		const getAllDocumentClasses = () => {
			documentClassApi.getAllDocumentClasses()
				.then(data => {
					allDocumentClasses.value = data;
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				});
		}

		/** Get all custom actions */
		const getCustomActions = () => {
			validationApi.getCustomValidationActions()
				.then((scripts: CustomValidationActionDto[]) => {
					const customScripts = scripts.filter(script => script.placement === 'menu');
					if (customScripts) {
						customActions.value = customScripts;
					}
					const customTableScripts = scripts.filter(script => script.placement === 'table');
					if (customTableScripts) {
						customTableActions.value = customTableScripts;
					}
				})
				.catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/**
		 * On keydown to check the shortcut, so that execute the custom validation action
		 * @param event
		 * @param allCustomActions
		 */
		const onCustomActionShortcut = (event: KeyboardEvent, allCustomActions: CustomValidationActionDto[]) => {
			allCustomActions.forEach((action: CustomValidationActionDto) => {
				if (action && action.shortcut && action.shortcut.key) {
					const currentEventCodeKeys = event.code.match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g);
					if ((currentEventCodeKeys && currentEventCodeKeys[1] === action.shortcut.key.toUpperCase()) && (
						(action.shortcut.ctrl && !action.shortcut.alt && ((event.ctrlKey || event.metaKey) && !event.altKey))
						|| (action.shortcut.ctrl && action.shortcut.alt && (event.altKey && (event.ctrlKey || event.metaKey)))
						|| (action.shortcut.alt && !action.shortcut.ctrl && (event.altKey && (!event.ctrlKey || !event.metaKey))))
					) {
						event.preventDefault();
						executeCustomValidationAction(action.id as string);
					}
				}
			})
		}

		/**
		 * Validates a document and then exports it
		 */
		const validateAndExportDocument = async () => {
			blocked.buttons.save = true;
			buildValidationRequest();

			try {
				const nextDocumentId = await getNextDocument(true);
				await singlePromiseValidation.execute(documentApi.saveValidateAndExportDocument(props.documentId, validationRequest));
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Validation.Dialogs.SaveDocument.Success'));
				handleNextDocumentId(nextDocumentId);
			} catch (response) {
				// Check if actually a response object, show generic error if not
				if (response.json == null) {
					ToastManager.showError(toast, t('Squeeze.Validation.Error'), String(response));
					return;
				}

				// Status 400 may be returned if document is invalid, saving failed or export failed
				if (response.status == 400) {
					const completeResponse = await response.json() as SaveValidateAndExportDocumentResponseDto;
					const validationResponseDto = completeResponse.validationResponse;
					// eslint-disable-next-line no-mixed-spaces-and-tabs
					// Document is not valid
					if (validationResponseDto.valid !== true) {
						handleValidationFieldResponse(validationResponseDto.fields!);
						ToastManager.showError(toast, t('Squeeze.Validation.ErrorInvalidDocument'));
						return;
					}

					// Check if all exports are fine
					if (completeResponse.exportException && completeResponse.exportException.message) {
						showExportError.value = true;
						exportErrorMessage.value = completeResponse.exportException.message;
					}
					else if (completeResponse.documentSaveException && completeResponse.documentSaveException.message) {
						showExportError.value = true;
						exportErrorMessage.value = completeResponse.documentSaveException.message;
					}
					else {
						ToastManager.showError(toast, t('Squeeze.Validation.Error'), t('Squeeze.Validation.Dialogs.SaveDocument.Error'));
					}
					blocked.buttons.save = false;
				} else {
					const completeResponse = await response.json() as ErrorDto;
					ToastManager.showError(toast, t('Squeeze.Validation.Error'), t('Squeeze.Validation.Dialogs.SaveDocument.Error') + "\n" + completeResponse.message);
					blocked.buttons.save = false;
				}
			}
		}

		/**
		 * Validation Key Combination
		 * @param event
		 */
		const onKeydown = (event: KeyboardEvent) => {
			// save document - If key strg/ctrl and S pressed, then validates a document and then exports it
			if (event.code === "KeyS" && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				event.preventDefault();
				if (!blocked.buttons.save) {
					validateAndExportDocument();
				}
			}
			// suspend document - If key strg/ctrl and B pressed, then suspend the document
			if (event.code === "KeyB" && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				event.preventDefault();
				if (!blocked.buttons.suspend) {
					showDialog('suspendDocument');
				}
			}
			// delete document - If key strg/ctrl and L pressed, then open the delete dialog of a document
			if (event.code === 'KeyL' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				event.preventDefault();
				showDialog('deleteDocument');
			}
			// open hotKey-List - If key F1 pressed, then open the hotKey-List
			if (event.code === 'F1') {
				showHotKeyList.value = !showHotKeyList.value;
			}
			// open headTraining - If key strg/ctrl and F2 pressed, then open the headTraining
			if (event.code === 'F2' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				if (!blocked.buttons.training) {
					if (showPositionTraining.value || showFormHeadTraining.value || showFormPositionTraining.value || showLocatorTest.value) {
						showPositionTraining.value = false;
						showFormHeadTraining.value = false;
						showFormPositionTraining.value = false;
						showLocatorTest.value = false;

						// reset markedRegions in viewer
						viewerMarkedRegions.value = [];
					}
					showHeadTraining.value = true;
					hideValidationButtons.value = true;
					toggleSplitterTable();
				} else {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Training.NoTrainingAccessMessage', { training: t('Squeeze.Training.FieldTraining')}));
				}
			}
			// open positionTraining - If key strg/ctrl and F3 pressed, then open the positionTraining
			if (event.code === 'F3' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				if (!blocked.buttons.training) {
					if (showHeadTraining.value || showFormHeadTraining.value || showFormPositionTraining.value || showLocatorTest.value) {
						showHeadTraining.value = false;
						showFormHeadTraining.value = false;
						showFormPositionTraining.value = false;
						showLocatorTest.value = false;
					}
					showPositionTraining.value = true;
					hideValidationButtons.value = true;
					toggleSplitterTable();
				} else {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Training.NoTrainingAccessMessage', { training: t('Squeeze.Training.PositionTraining')}));
				}
			}
			// open Log-List - If key F4 pressed, then open the Log-List
			if (event.code === 'F4' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				if (store.state.scopes.sqzAdmin && !blocked.buttons.changeDocumentClass) {
					showLog.value = !showLog.value;
				}
			}
			// open locatorTesting - If key strg/ctrl and F12 pressed, then open the locatorTesting
			if (event.code === 'F12' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				if(showHeadTraining.value || showPositionTraining.value || showFormHeadTraining.value || showFormPositionTraining.value) {
					showHeadTraining.value = false;
					showPositionTraining.value = false;
					showFormHeadTraining.value = false;
					showFormPositionTraining.value = false;

					// reset markedRegions in viewer
					viewerMarkedRegions.value = [];
				}
				showLocatorTest.value = true;
				hideValidationButtons.value = true;
				toggleSplitterTable();
			}
			// delete current line  - If key strg/ctrl and Entf pressed, then delete the current line
			if (event.code === 'Delete' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey) || event.code === 'Backspace' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				if (activeTableRowIndex.value) {
					const tableComponent: any = tableInValidation.value[activeTableId.value as any];
					tableComponent.deleteRow(activeTableRowIndex.value);
				}
			}
			// insert a new line  - If key strg/ctrl and Einf pressed, then insert a new line
			if ((event.code === 'Insert' || event.code === 'KeyY') && !event.shiftKey && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				if (activeTableRowIndex.value) {
					const tableComponent: any = tableInValidation.value[activeTableId.value as any];
					tableComponent.createNewRow(activeTableRowIndex.value as number);
				}
			}
			// copy the current line & insert in next line  - If key strg/ctrl + shift and Einf pressed, then copied the current line and insert in next line
			if ((event.code === 'Insert' || event.code === 'KeyY') && event.shiftKey && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				if (activeTableRowIndex.value) {
					const tableComponent: any = tableInValidation.value[activeTableId.value as any];
					tableComponent.copyRow(activeTableRowIndex.value as number);
				}
			}

			// v1 viewer shortcuts
			if (viewerClient.value) {
				// viewer-image optimized width - If key POS1 pressed, then optimized the width of viewer-image
				if (event.code === 'Home' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
					viewerClient.value.toggleFullWidth();
				}
				// viewer-image optimized height - If key strg/ctrl and END pressed, then optimized the height of viewer-image
				if (event.code === 'End' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
					viewerClient.value.toggleFullHeight();
				}
				// viewer-image scroll up - If key PageUp pressed, then scroll up in viewer-image
				if (event.code === 'PageUp') {
					event.preventDefault();
					viewerClient.value.pageUp();
				}
				// viewer-image scroll down - If key PageDown pressed, then scroll down in viewer-image
				if (event.code === 'PageDown') {
					event.preventDefault();
					viewerClient.value.pageDown();
				}
			} else if (NEW_SQUEEZE_VIEWER) {
				// v2 viewer shortcuts
				// viewer-image optimized width - If key POS1 pressed, then optimized the width of viewer-image
				if (event.code === 'Home' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
					SqueezeViewer.value.onClickResetZoom();
				}
				// viewer-image optimized height - If key strg/ctrl and END pressed, then optimized the height of viewer-image
				if (event.code === 'End' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
					SqueezeViewer.value.onClickHeightZoom();
				}
				// viewer-image scroll up - If key PageUp pressed, then scroll up in viewer-image
				if (event.code === 'PageUp') {
					event.preventDefault();
					SqueezeViewer.value.openNextPage();
				}
				// viewer-image scroll down - If key PageDown pressed, then scroll down in viewer-image
				if (event.code === 'PageDown') {
					event.preventDefault();
					SqueezeViewer.value.openPreviousPage();
				}
				// viewer-image fixation - If key strg/ctrl and Space pressed, then fixate the viewer-image
				if (event.code === 'Space' && event.ctrlKey) {
					event.preventDefault();
					SqueezeViewer.value.toggleSetView();
				}
			}

			// check length of custom actions in menu to check
			if (customActions.value.length) {
				onCustomActionShortcut(event, customActions.value);
			}

			// check length of custom actions in table
			if (customTableActions.value.length) {
				onCustomActionShortcut(event, customTableActions.value);
			}
		}

		/**
		 * Deletes a document
		 * @param comment
		 */
		const deleteDocument = async (comment: string) => {
			blocked.buttons.delete = true;
			const nextDocumentId = await getNextDocument(true);
			documentApi.deleteDocumentById(props.documentId, comment)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Validation.Dialogs.DeleteDocument.Success'));
					handleNextDocumentId(nextDocumentId);
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Validation.Dialogs.DeleteDocument.Error') + "\n" + reason);
				})
				.finally(()=> {
					dialogs.deleteDocument.display = blocked.buttons.delete = false;
				})
		}

		/**
		 * Suspends a document
		 * @param comment
		 */
		const suspendDocument = async (comment: string) => {
			blocked.buttons.suspend = true;

			buildValidationRequest();
			const nextDocumentId = await getNextDocument(true);
			documentApi.saveAndSuspendDocument(props.documentId, comment, validationRequest)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Validation.Dialogs.SuspendDocument.Success'));
					handleNextDocumentId(nextDocumentId);
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Validation.Dialogs.SuspendDocument.Error') + "\n" + reason);
				})
				.finally(()=> {
					dialogs.suspendDocument.display = blocked.buttons.suspend = false;
				})
		}

		/** Emitted after a mail has been send successfully */
		const afterSendSuccess = () => {
			dialogs.sendMail.display = false;
		}

		/** Saves the new Document Class to a document */
		const saveNewDocumentClass = async () => {
			loaded.value = false;
			dialogs.changeDocumentClass.loading = true;
			const nextDocumentId = await getNextDocument(true);
			documentApi.changeDocumentClass(props.documentId, newDocumentClass.value, dialogs.changeDocumentClass.trainDocument)
				.then(() => {
					loaded.value = true;
					dialogs.changeDocumentClass.display = false;
					handleNextDocumentId(nextDocumentId);
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					loaded.value = true;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => dialogs.changeDocumentClass.loading = false)
		}

		/**
		 * Is triggered when the Form changes
		 * @param documentClass
		 * @param trainDocument
		 */
		const onChangeDocumentClassChangeForm = (documentClass: number, trainDocument: boolean) => {
			newDocumentClass.value = documentClass;
			dialogs.changeDocumentClass.trainDocument = trainDocument;
		}

		/** Triggered when the "read only"-button in the Locked Dialog is clicked */
		const onClickDocumentReadOnly = () => {
			dialogs.lockedDocument.display = false;
			mode.value = "readonly";
			isReadOnlyMode.value = true;
			hideTableButtons.value = true;
		}

		/**
		 * Triggered when Enter/Tab-Keydown in first or last fieldGroup field
		 * @param currentFieldGroup
		 * @param isNextField
		 */
		const enterDocumentGroup = async (currentFieldGroup: number, isNextField: boolean) => {
			const currentGroupIndex = documentClassFieldsByGroup.value.findIndex(fieldGroup => fieldGroup.id === currentFieldGroup);

			if(currentGroupIndex !== -1 && !isNextField) {
				// Triggered when a field of headFieldGroup was the first element, then focused the last element in the previous headFieldGroup
				const prevGroupIndex = currentGroupIndex - 1;
				if(prevGroupIndex !== headRefFieldElements.value.length && prevGroupIndex >= 0) {
					activeGroupTab.value = prevGroupIndex;
					await nextTick();
					headRefFieldElements.value[prevGroupIndex][headRefFieldElements.value[prevGroupIndex].length -1].element.$el.focus();
				}
			} else if(currentGroupIndex !== documentClassFieldsByGroup.value.length && isNextField) {
				// Triggered when a field of headFieldGroup was the last element, then focused the next element in the next headFieldGroup
				const nextGroupIndex = currentGroupIndex + 1;
				if(nextGroupIndex !== headRefFieldElements.value.length) {
					activeGroupTab.value = nextGroupIndex;
					await nextTick();
					headRefFieldElements.value[nextGroupIndex][0].element.$el.focus();
				} else {
					// Triggered when last element of headFieldGroup is active, then focus the first cell in table
					if (allTableRefElements.value[0] && allTableRefElements.value[0][0] && allTableRefElements.value[0][0].element && allTableRefElements.value[0][0].element.$el) {
						allTableRefElements.value[0][0].element.$el.focus();

						if (allTableRefElements.value[0][0].element.$el.firstElementChild) {
							allTableRefElements.value[0][0].element.$el.firstElementChild.focus();
						}
					} else {
						// Triggered when last element of headFieldGroup is active and no more fields or table cells exist
						await validateDocument();
					}
				}
			}
		}

		/**
		 * Triggered when an Autocomplete-Item is hovered and it has an DocumentField-Value
		 * @param fieldValue
		 */
		const onHoverItemAutocomplete = (fieldValue: DocumentFieldValue) => {
			if (fieldValue.boundingBox) {
				viewerMarkedRegions.value = [fieldValue.boundingBox] as BoundingBox[];
				viewerClient.value?.markRegion(fieldValue.boundingBox);
			}
		}

		/**
		 * Sets the Object for the currently active table
		 * @param {number} tableId Id of the table to get
		 */
		const setActiveTable = (tableId: number) => {
			showTableSplitter.value = true;
			if (!activeTableId.value) {
				activeTableId.value = tableId;
			} else if (activeTableId.value !== tableId) {
				activeTableId.value = tableId;
			} else {
				activeTableId.value = null;
				showTableSplitter.value = false;
			}
			const table = tables.value.find(table => table.id === activeTableId.value);
			activeTable.value = null;

			if (table) {
				activeTable.value = table;
			}
		}

		/** Get user auth */
		const getUserAuth = computed(() => {
			if (ClientManager.getInstance().login.activeAuth === AuthTypes.Bearer) {
				return { 'Authorization': "Bearer " + bearerToken.value }
			}

			const { username, password } = ClientManager.getInstance().getSqueezeCredentials();
			return { 'Authorization': "Basic " + btoa(`${username}:${password}`) }
		});

		/**
		 * Get document field value
		 * @param fieldName
		 */
		const getDocumentFieldValue = (fieldName: string) => {
			return documentFields.value[getDocumentFieldIndex(fieldName)]?.value?.value;
		}

		const setupViewer = () => {
			// Only used by old Squeeze-Viewer
			const iframe = document.getElementById("SqueezeViewer");
			if (iframe instanceof HTMLIFrameElement && iframe.contentWindow != null) {
				viewerClient.value = new ViewerClient(iframe.contentWindow);

				viewerClient.value.onSelectArea = async (value, boundingBox) => {
					// if readOnlyMode true, then prevent to get the value of draw area in viewer
					if (isReadOnlyMode.value && (!showHeadTraining.value && !showPositionTraining.value && !showFormHeadTraining.value && !showFormPositionTraining.value)) {
						return;
					}

					if (showHeadTraining.value) {
						if (activeFieldTraining.value === 'keyWordPattern') {
							if (value === trainingValues.keyWordPattern) {
								trainingValues.keyWordPattern = '';
							}
							await nextTick(() => trainingValues.keyWordPattern = value);
							if (trainingValues.keyWordRegion) {
								mapBoundingBoxDtoToViewerBbox(trainingValues.keyWordRegion, boundingBox);
							}
						}
						if (activeFieldTraining.value === 'valuePattern') {
							if (value === trainingValues.valuePattern) {
								trainingValues.valuePattern = '';
							}
							await nextTick(() => trainingValues.valuePattern = value);
							if (trainingValues.valueRegion) {
								mapBoundingBoxDtoToViewerBbox(trainingValues.valueRegion, boundingBox);
							}
						}
					} else if (showPositionTraining.value) {
						if (activePositionTraining.value === 'valuePattern') {
							if (value === positionTrainingValues.valuePattern) {
								positionTrainingValues.valuePattern = '';
							}
							await nextTick(() => positionTrainingValues.valuePattern = value);
						}
						if (activePositionTraining.value === 'columnRegion') {
							if (positionTrainingValues.columnRegion) {
								mapBoundingBoxDtoToViewerBbox(positionTrainingValues.columnRegion, boundingBox);
							}
						}
					} else if (showFormHeadTraining.value || showFormPositionTraining.value) {
						if (activeFieldTraining.value === 'indicatorKeyWordPattern') {
							if (value === indicatorOfFormTraining.keyWordPattern) {
								indicatorOfFormTraining.keyWordPattern = '';
							}
							await nextTick(() => indicatorOfFormTraining.keyWordPattern = value);
							if (indicatorOfFormTraining.keyWordRegion) {
								mapBoundingBoxDtoToViewerBbox(indicatorOfFormTraining.keyWordRegion, boundingBox);
								indicatorOfFormTraining.keyWordRegion.page = Number(boundingBox.page);
							}
						}

						if (activeFieldTraining.value === 'headFieldValuePattern') {
							if (value === headFieldOfRegionInFormTraining.valuePattern) {
								headFieldOfRegionInFormTraining.valuePattern = '';
							}
							await nextTick(() => headFieldOfRegionInFormTraining.valuePattern = value);
							if (headFieldOfRegionInFormTraining.valueRegion) {
								mapBoundingBoxDtoToViewerBbox(headFieldOfRegionInFormTraining.valueRegion, boundingBox);
								headFieldOfRegionInFormTraining.valueRegion.page = Number(boundingBox.page);
							}
						}
						if (activeFieldTraining.value === 'itemFieldValuePattern') {
							if (value === itemFieldOfRegionInFormTraining.valuePattern) {
								itemFieldOfRegionInFormTraining.valuePattern = '';
							}
							await nextTick(() => itemFieldOfRegionInFormTraining.valuePattern = value);
							if (itemFieldOfRegionInFormTraining.valueRegion) {
								mapBoundingBoxDtoToViewerBbox(itemFieldOfRegionInFormTraining.valueRegion, boundingBox);
								itemFieldOfRegionInFormTraining.valueRegion.page = Number(boundingBox.page);
							}
						}
						if (activeFieldTraining.value === 'regionAnchorKeyWordPattern') {
							if (value === regionAnchorOfFormTraining.keyWordPattern) {
								regionAnchorOfFormTraining.keyWordPattern = '';
							}
							await nextTick(() => regionAnchorOfFormTraining.keyWordPattern = value);
							if (regionAnchorOfFormTraining.keyWordRegion) {
								mapBoundingBoxDtoToViewerBbox(regionAnchorOfFormTraining.keyWordRegion, boundingBox);
								regionAnchorOfFormTraining.keyWordRegion.page = Number(boundingBox.page);
							}
						}
					} else {
						if (activeDocumentField) {
							// FIXME: Is there a better way to assert non-null?
							const fieldIndex = getDocumentFieldIndex(activeDocumentField.name as any);
							const documentField = documentFields.value[fieldIndex];
							const documentFieldValue = documentFields.value[fieldIndex];

							if(documentField && documentFieldValue && !activeDocumentField.readonly) {
								if (documentField.dataType?.toLowerCase() == "amount") {
									documentFields.value[fieldIndex].value!.value = formatAmount(value as any);
								} else {
									documentFields.value[fieldIndex].value!.value = value;
								}
								validateDocument();
							}
						}
						else if (activeTableCell.value && activeTableCell.value.columnId) {
							const column = activeTable.value!.columns?.find(column => column.name === activeTableCell!.value!.columnName);

							if(column && column.dataType) {
								if (column.dataType.toLowerCase() == "amount") {
									//activeTableCell.value = parseNumbers(value) as any; // TODO: Add Number fields to Table
									activeTableCell.value.value = value;
								} else {
									activeTableCell.value.value = value;
								}
								validateDocument();
							}
						}
					}
				}

				viewerClient.value.onOpenFieldTraining = () => {
					const principal = getDocumentFieldValue("Creditor");
					principal ? viewerClient.value!.openFieldTraining(principal) : ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Viewer.Error.NoCreditorField'));
				}

				viewerClient.value.onOpenItemTraining = () => {
					const principal = getDocumentFieldValue("Creditor");
					principal ? viewerClient.value!.openItemTraining(principal) : ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Viewer.Error.NoCreditorField'));
				}

				viewerClient.value.onOpenFieldTrainingList = () => {
					const principal = getDocumentFieldValue("Creditor");
					principal ? viewerClient.value!.openFieldTrainingList(principal) : ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Viewer.Error.NoCreditorField'));
				}
			}
		}

		/**
		 * Sort fields by order
		 * @param fieldA
		 * @param fieldB
		 */
		const sortFieldsByOrder = (fieldA: DocumentField, fieldB: DocumentField) => {
			if (fieldA && fieldB) {
				if ((fieldA.sortOrder && fieldB.sortOrder) && (fieldA.sortOrder < fieldB.sortOrder)) {
					return -1;
				}
				if((fieldA.sortOrder && fieldB.sortOrder) && (fieldA.sortOrder > fieldB.sortOrder)) {
					return 1;
				}
			}
			return 0;
		}

		/** Get TrainingKey of DocumentClass */
		const getDocumentClassTrainingKey = () => {
			documentClassApi.getDocumentClassTrainingKey(props.documentClassId)
				.then(trainingKey => {
					const trainingKeyId = trainingKey.ids![0];
					if (trainingKey.ids?.length === 0) {
						blocked.buttons.training = true;
					}
					if (trainingKey.ids!.length !== 1 && trainingKey.ids!.length > 1) {
						blocked.buttons.training = true;
						throw t('Squeeze.Training.MultiTrainingKey');
					}
					documentFields.value.forEach(field => {
						if (field.id === trainingKeyId) {
							trainingKeyField.value = field;
						}
					})
					if (trainingKeyField.value === null && trainingKey.ids!.length === 1) {
						blocked.buttons.training = true;
						throw t('Squeeze.Training.TrainingKeyNotFound');
					}
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				})
		}

		/**
		 * Field Layout by rows
		 * @param fieldGroupId Id of the field group
		 */
		const fieldLayoutByRows = (fieldGroupId: number) => {
			const fieldLayout = fieldLayoutComplete.fieldLayout.find(layout => layout.fieldGroupId === fieldGroupId);
			if (!fieldLayout) {
				return;
			}

			const fieldDetails: any = fieldLayout.fieldLayout;
			const rows = fieldDetails.map((widget: any) => { return widget.row }).filter((value: any, index: number, self: any) => self.indexOf(value) === index);

			const filteredRows = rows.map((row: any) => { return fieldDetails.filter((widget: any) => widget.row === row)});
			fieldLayoutComplete.fieldLayoutRows.push({
				fieldGroupId: fieldGroupId,
				rows: filteredRows,
			});
			if (filteredRows) {
				filteredRows.forEach((rows: any) => {
					if (rows.length === 1) {
						if (rows[0].width && (rows[0].width + rows[0].offset < 12)) {
							rows[0].offsetSize = rows[0].offset;
						} else if (rows[0].width && rows[0].width !== 12 && (rows[0].width + rows[0].offset === 12)) {
							const offsetSize = 12 - rows[0].width;
							rows[0].offsetSize = offsetSize;
						}
					} else if (rows.length > 1) {
						let previousWidgetElement: GridStackFieldDetails;
						rows.forEach((widget: any, index: number) => {
							previousWidgetElement = rows[index -1];

							if (rows.length > 1 && widget.offset > 0 && previousWidgetElement && previousWidgetElement.width) {
								widget.offsetSize = widget.offset - previousWidgetElement.width;

								// calc field offset when more than two fields in a row
								if (previousWidgetElement && previousWidgetElement.offset && previousWidgetElement.width) {
									widget.offsetSize = widget.offset - (previousWidgetElement.offset + previousWidgetElement.width);
								}
							} else {
								widget.offsetSize = widget.offset;
							}
						})
					}
				})
			}
		}

		/**
		 * Check field colSize
		 * @param fieldGroupId Id of the field group
		 */
		const checkFieldsInGroup = (fieldGroupId: number) => {
			const currentGroup = documentClassFieldsByGroup.value.find(fieldGroup => fieldGroup.id === fieldGroupId);
			const currentFieldLayout = fieldLayoutDetails.value.find(fieldGroup => fieldGroup.fieldGroupId === fieldGroupId);
			if (currentGroup && currentFieldLayout) {
				const fieldDetails: any = currentFieldLayout.fieldLayout;

				currentGroup.fields!.forEach((field: FieldInSameLine) => {
					fieldDetails.find((detail: any) => {
						if (detail.id === field.id || detail.content === field.description) {
							field.colSize = 'p-col-' + detail.width;
						}
					})
				});

				fieldLayoutByRows(fieldGroupId);
			}
		}

		/**
		 * Get the colSize of fields when in same line as previous field
		 * @param line Array with index of fields
		 */
		const setColSize = (line: number[]) => {
			let colSize: string = 'p-col-12';
			switch (line.length) {
			case 2: colSize = 'p-col-6'; break;
			case 3: colSize = 'p-col-4'; break;
			default: break;
			}

			return colSize;
		}

		/**
		 * Set the colSize of current field of field group
		 * @param line Array with index of fields
		 * @param fieldGroup Current field group
		 */
		const setColSizeOfCurrentField = (line: number[], fieldGroup: DocumentClassFieldGroup) => {
			line.forEach((fieldIndex: number) => {
				const currentField: FieldInSameLine = fieldGroup.fields![fieldIndex];
				if (currentField) {
					currentField.colSize = setColSize(line);
					fieldGroup.fields![fieldIndex] = currentField;
				}
			})
		}

		/** Check which fields are in a group, when field in same line */
		const checkSameLineFieldsInGroup = () => {
			let line: number[] = [];
			documentClassFieldsByGroup.value.forEach((group: DocumentClassFieldGroup) => {
				group.fields?.forEach((field: FieldInSameLine, index: number) => {
					if (!field.hidden) {
						if (!field.sameLineAsPreviousField) {
							if (line.length > 0) {
								line.forEach((fieldIndex: number) => {
									const currentField: FieldInSameLine = group.fields![fieldIndex];
									if (currentField) {
										currentField.colSize = setColSize(line);
										group.fields![fieldIndex] = currentField;
									}
								})
								line = [];
								line.push(index);
							} else {
								line.push(index);
							}
						} else {
							if (line.length === 3) {
								line.forEach((fieldIndex: number) => {
									const currentField: FieldInSameLine = group.fields![fieldIndex];
									currentField.colSize = 'p-col-4';
									group.fields![fieldIndex] = currentField;
								})
								line = [];
								line.push(index);
							} else {
								line.push(index);

								// check if is the last field of group, then set colSize
								if(group.fields?.length === index +1) {
									setColSizeOfCurrentField(line, group);
									line = [];
								}
							}
						}
					} else if (field.hidden && group.fields!.length === index +1) {
						// check if is the last field of group hidden, then set colSize
						setColSizeOfCurrentField(line, group);
						line = [];
					}
				})
			})
		}

		/** Get fieldGroup layouts from documentClass */
		const getDocumentClassFieldGroupLayouts = () => {
			documentClassApi.getDocumentClassFieldGroupLayouts(props.documentClassId)
				.then(response => response.json().then((fieldLayouts: FieldGroupLayout[]) => {
					// check if all field groups have a layout
					if (documentClassFieldsByGroup.value.length > fieldLayouts.length || fieldLayouts.length < 1) {
						// check if field group has no layout
						const fieldGroupWithoutLayout = documentClassFieldsByGroup.value.filter(fieldGroup => !fieldLayouts.find(layout => layout.fieldGroupId === fieldGroup.id));
						fieldGroupWithoutLayout.forEach(fieldGroup => {
							// set default layout
							fieldLayouts.push({
								fieldGroupId: fieldGroup.id as number,
								fieldLayout: "[]",
							});
						})
					}

					fieldLayouts.forEach((layout: FieldGroupLayout) => {
						if (JSON.parse(layout.fieldLayout).length > 0) {
							fieldLayoutDetails.value.push({
								fieldGroupId: layout.fieldGroupId,
								fieldLayout: JSON.parse(layout.fieldLayout),
							});
						} else {
							// set default values for a field in layout
							let allFieldsOfGroup: any[] = [];
							const fieldGroupFields = documentClassFieldsByGroup.value.find(fieldGroup => fieldGroup.id === layout.fieldGroupId);

							if (fieldGroupFields) {
								allFieldsOfGroup = fieldGroupFields.fields!.map((field, fieldGroupIndex) => {
									const fieldWidget = {
										id: field.id,
										width: 12,
										row: fieldGroupIndex,
										offset: 0,
										type: 'field',
										fieldGroupId: layout.fieldGroupId,
										documentClassId: props.documentClassId,
									};
									return fieldWidget;
								})
							}

							fieldLayoutDetails.value.push({
								fieldGroupId: layout.fieldGroupId,
								fieldLayout: allFieldsOfGroup,
							});

							// check here if feature flag for layout is false (deactivated)
							//checkSameLineFieldsInGroup();
						}
						checkFieldsInGroup(layout.fieldGroupId);
					})
				}))
				.catch(response => {
					if (!response.json) {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + response);
						return;
					}
					throw response;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/**
		 * Set focus on the first field of a field group
		 * Triggered also when the field group (tab) is changed
		 */
		const focusOnFirstField = async () => {
			await nextTick();
			const currentFgRefFieldElements = headRefFieldElements.value[activeGroupTab.value];
			// set focus on first InputField or DropdownField
			if (currentFgRefFieldElements && currentFgRefFieldElements[0] && !dialogs.lockedDocument.display) {
				if (currentFgRefFieldElements[0].element.dropdown || currentFgRefFieldElements[0].field.lookup.active) {
					// focus first field when field is a dropdown
					const input = currentFgRefFieldElements[0].element.$el.querySelector('.p-autocomplete-input.p-inputtext');
					input.focus();
				} else {
					currentFgRefFieldElements[0].element.$el.focus();
				}
			}
		}

		/**
		 * Get all headRefField of a fieldGroup
		 * @param headRefField
		 */
		const allHeadRefFields = async (headRefField: any) => {
			// Filter all visible Fields from Array
			const allVisibleFields = headRefField.filter((field: any) => field.field.hidden === false);
			headRefFieldElements.value.push(allVisibleFields);

			// get focus on first field
			await focusOnFirstField();
		}

		/** Reload document fields as well as validation fields */
		const reloadData = () => {
			loaded.value = false;
			activeGroupTab.value = 0;
			headRefFieldElements.value = [];
			blocked.buttons.save = blocked.buttons.suspend = blocked.buttons.delete = blocked.buttons.menu = true;
			const documentClassFieldsPromise = documentClassApi.getAllDocumentClassFields(props.documentClassId);
			const documentPromise = documentApi.getDocumentById(props.documentId);
			readonly.value = false;
			dialogs.lockedDocument.locked = false;
			viewerShouldObserveResizing.value = false;
			isReadOnlyMode.value = false;
			tableSplitterSetAfterValidation.value = false;

			Promise.all([documentClassFieldsPromise, documentPromise])
				.then(promises => {
					const documentClassFieldCollection = promises[0];
					const document = promises[1];
					currentDocument.value = document;
					const user = store.state.user;
					const fieldGroups = document.fieldGroups;
					documentClassFieldsByGroup.value = [];

					// A document may or may not be in a workflow context
					if (document.workflowContext) {
						Object.assign(documentWorkflowContext, document.workflowContext);
						lockingUserId.value = document.workflowContext.lockedBy!;

						if (document.workflowContext.step !== "Validation" && document.workflowContext.step !== 'Auto-Validation') {
							readonly.value = true;
						}
						// There is no user that is blocking the document
						else if (!document.workflowContext.lockedBy) {
							lockDocument();
						}
						// If the user.id is equal to the locking user, everything is ok
						else if (user && user.id === document.workflowContext.lockedBy) {
							lockedByCurrentUser.value = true;
						}
						// If the blocking user is not the current user, the document is blocked!
						else {
							dialogs.lockedDocument.locked = true;
							dialogs.lockedDocument.display = true;
							// If the Document is locked, it can only be viewed as read-only
							setReadOnlyMode();
						}
					} else {
						readonly.value = true;
					}

					document.fields.forEach(field => {
						if (field.dataType?.toLowerCase() === "amount") {
							if (field.value != null) {
								if (field.value.value != null) {
									// FORMAT TO NUMBER
									field.value.value = formatAmount(field.value.value);
								} else {
									field.value.value = parseFloat("0") as any;
								}
							}
						}
					});

					batchClassId.value = document.batchClassId!;
					documentFields.value = document.fields;
					tables.value = document.tables;

					getDocumentClassTrainingKey();

					// Get Classification-Classes for Batch-Class
					if (batchClassId.value) {
						batchClassApi.getBatchClassClassifications(batchClassId.value)
							.then(data => {
								dialogs.changeDocumentClass.classificationClasses = data.filter(classificationClass => classificationClass.documentClassId !== Number (route.params.documentClassId));

								// Disable Button for Change, if there is no Classification Class to Change to
								if (dialogs.changeDocumentClass.classificationClasses && dialogs.changeDocumentClass.classificationClasses.length !== 0) {
									blocked.buttons.changeDocumentClass = true;
								}
							})
							.catch(response => response.json().then ((err: any) => {
								ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
							}))
					}

					/** Sort by internal Description. TODO: Delete later? */
					tables.value.sort((a: any, b: any) => {
						if (a.description && b.description) {
							if (a.description > b.description) {
								return 1;
							}
							else {
								return -1;
							}
						}
						return 0;
					})

					// Get first active table, not yet
					// Get first active table
					for (const table of tables.value) {
						if (table.id) {
							activeTableId.value = table.id;
							checkTableBehaviour(table);
							getActiveTable();
							break;
						}
					}

					fieldGroups.forEach((fieldGroup) => {
						if(fieldGroup.type === 0) {
							const fieldsOfFieldGroup = documentClassFieldCollection.filter(field => field.fieldGroupId === fieldGroup.id);
							const documentFieldGroup = {
								id: fieldGroup.id,
								name: fieldGroup.name,
								description: fieldGroup.description,
								type: fieldGroup.type,
								fields: fieldsOfFieldGroup.sort(sortFieldsByOrder),
							}
							documentClassFieldsByGroup.value.push(documentFieldGroup);
						}
					});

					// check if to get fieldLayout OR sameLineAsPreviousField
					if (store.state.featureSet.validationFieldLayout) {
						// If there are field layout details already, only set classes for offsets/widths
						if (fieldLayoutDetails.value.length === 0) {
							getDocumentClassFieldGroupLayouts();
						} else {
							fieldGroups.forEach((fieldGroup) => {
								checkFieldsInGroup(fieldGroup.id!)
							})
						}
					} else {
						checkSameLineFieldsInGroup();
					}

					currentErrorField.value = null;
					return validateDocument();
				})
				.then(validationResult => {
					blocked.buttons.save = !validationResult;
				})
				.catch(reason => {
					// check status - go to ErrorPage-Component
					if (reason.status === 401) {
						router.push({ name: 'ErrorPage', params: { reason: 'notAccess' }, query: { status: reason.status }});
					} else if (reason.status === 403) {
						router.push({ name: 'ErrorPage', params: { reason: 'forbidden' }, query: { status: reason.status }});
					} else if (allDocumentClasses.value.filter(docClass => docClass.id !== props.documentClassId)) {
						dialogs.errorDocument.display = true;
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
					}
				})
				.finally(()=> {
					loaded.value = true;
					viewerShouldObserveResizing.value = true;
					blocked.buttons.suspend = blocked.buttons.delete = blocked.buttons.menu = false;

					// Always scroll up on load
					viewerMarkedRegions.value = [{page: 1, x0: 1, x1: 1, y0: 1, y1: 1}];

					// Set everything to read only, if the document is blocked
					if (dialogs.lockedDocument.locked || readonly.value) {
						setReadOnlyMode();
					}
				})
		}

		/** Unlock viewed document */
		const unlockViewedDocument = async () => {
			await documentApi.unlockDocument(props.documentId)
				.then(() => {
					reloadData();
				})
				.catch((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})
				.finally(() => {
					dialogs.lockedDocument.display = false;
					blocked.buttons.training = false;
					blocked.buttons.testing = false;
					blocked.buttons.attachment = false;
					mode.value = 'edit';
					isReadOnlyMode.value = false;
					hideTableButtons.value = false;
				})
		}

		/** On Route change */
		const onRouteChange = () => {
			if (route.name === "ValidateEntry") {
				nextTick(() => {
					reloadData();
				})
			}
		}

		/** On page ready */
		onMounted(async () => {
			// get current search request from local storage
			const currentSearchRequestInLocalStorage = localStorage.getItem('searchRequest');
			if (Object.keys(props.searchRequest) && currentSearchRequestInLocalStorage) {
				Object.assign(currentSearchRequest, JSON.parse(currentSearchRequestInLocalStorage));
				localStorage.removeItem('searchRequest');
			}

			window.addEventListener('beforeunload', onUnload, true);
			window.addEventListener('onbeforeunload', onUnload, true);
			window.addEventListener('resize', showToolbarButtonText, true);
			window.addEventListener('keydown', onKeydown.bind(this), true);
			window.addEventListener('dragover', triggerShowDragDialog.bind(this), true);

			if (route.query.parent && route.query.parent === "QueueList") {
				blockBrowseButtons.value = true;
			}

			initDialogOptions();
			initToolbarOptions();
			await showToolbarButtonText();
			//loadQueryParams();
			reloadData();
			setupViewer();
			getAllDocumentClasses();
			getCustomActions();

			// width of left splitter panel by start load
			resizeObserver.value = new ResizeObserver(() => {
				toolbarButtonText.value = leftSplitterPanelContent.value.offsetWidth > 605;
			});

			// observe the leftSplitterPanelContent
			resizeObserver.value.observe(leftSplitterPanelContent.value);
		})

		/** Is triggered before a Component unmounts. Available since Vue 3.0 */
		onBeforeUnmount(() => {
			window.removeEventListener("beforeunload", onUnload, true)
			window.removeEventListener("onbeforeunload", onUnload, true)
			window.removeEventListener('resize', showToolbarButtonText, true);
			window.removeEventListener('keydown', onKeydown.bind(this), true);
			window.removeEventListener('dragover', triggerShowDragDialog.bind(this), true);

			// Unlock document
			unlockDocument(true);

			// Remove the listener when the Component unmounts, otherwise the same listener will be registered multiple times
			if (viewerClient.value) {
				viewerClient.value.removeListener()
			}

			// Clear bearer interval, if one exists
			if (bearerInterval.value) {
				clearInterval(bearerInterval.value);
				bearerInterval.value = null;
			}

			// unobserve the leftSplitterPanelContent
			resizeObserver.value.unobserve(leftSplitterPanelContent.value);
		});

		/** Watch locale to reset locale storage if needed */
		watch(locale, () => {
			initToolbarOptions();
		});

		watch(() => route.params, () => {
			onRouteChange();
		});

		watch(mode, () => {
			switch(mode.value) {
			case "readonly": setReadOnlyMode(); break;
			case "edit": break;
			default: break;
			}
		});

		return {
			t, locale, toast, route, store,
			NEW_SQUEEZE_VIEWER,
			mode,
			isReadOnlyMode,
			documentFields,
			allDocumentClasses,
			tables,
			documentClassFieldsByGroup,
			serverBaseUrl,
			loaded,
			show,
			validationRequest,
			viewerClient,
			activeDocumentField,
			activeTableCell,
			activeTableRowIndex,
			blocked,
			activeTableId,
			activeTable,
			batchClassId,
			newDocumentClass,
			hideTableButtons,
			dialogs,
			toolbarOptions,
			singlePromiseValidation,
			lockedByCurrentUser,
			lockingUserId,
			toolbarButtonText,
			showSplitDocument,
			hideValidationButtons,
			showHeadTraining,
			showPositionTraining,
			showFormHeadTraining,
			showFormPositionTraining,
			showLocatorTest,
			showTableSplitter,
			showTableSplitterGutter,
			showOcrResults,
			showDragDialog,
			startTimeByMouseDown,
			activeGroupTab,
			trainingValues,
			trainingKeyField,
			activeFieldTraining,
			positionTrainingValues,
			activePositionTraining,
			indicatorOfFormTraining,
			regionAnchorOfFormTraining,
			headFieldOfRegionInFormTraining,
			itemFieldOfRegionInFormTraining,
			readonly,
			resizeObserver,
			searchElements,
			viewerMarkedRegions,
			viewerShouldObserveResizing,
			headRefFieldElements,
			allTableRefElements,
			showHotKeyList,
			showLog,
			showUpload,
			uploadLabel,
			files,
			progress,
			allErrorMessages,
			currentErrorField,
			lastRoute,
			blockBrowseButtons,
			showExportError,
			exportErrorMessage,
			fieldLayoutDetails,
			fieldLayoutComplete,
			customActions,
			customTableActions,
			showCustomActionDialog,
			customActionDialogMessage,
			currentDocument,
			tableSplitterSetAfterValidation,
			currentSearchRequest,
			documentWorkflowContext,
			leftSplitterPanelContent,
			leftSplitterPanel,
			leftSplitterPanelWidth,
			SqueezeViewer,
			viewer,
			menu,
			opBadge,
			tableInValidation,
			documentTypeEnum,
			findTableIndex,
			disableBrowseButtons,
			docBaseUrl,
			viewerDrawingMode,
			validationFieldSet,
			resetSelectedRows,
			onHoverDocumentType,
			checkErrorSeverity,
			toggleErrorMessages,
			goBackToQueueList,
			goBackToValidationList,
			browseLeft,
			gotoDocument,
			goToNextDocument,
			reopenValidationView,
			disableViewerOnResizing,
			enableViewerAfterResizing,
			onSplitterMouseDown,
			onSplitterMouseUp,
			toggleSplitterTable,
			onSplitterResizeEnd,
			onSelectFiles,
			clearFiles,
			removeFile,
			manualFileUpload,
			fileUploader,
			onDragEnter,
			onDragLeave,
			onDrop,
			onFocusFieldTraining,
			onChangePositionTrainingValues,
			onFocusFieldOfPositionTraining,
			onMarkRegion,
			onMarkLines,
			onMarkWord,
			onSplitImageClick,
			getNextDocument,
			onEmptyList,
			handleNextDocumentId,
			markFieldByRegion,
			formatAmount,
			buildValidationRequest,
			handleValidationFieldResponse,
			handleValidationTableResponse,
			getAllErrorMessages,
			checkTableBehaviour,
			getActiveTable,
			toggleResultList,
			mapBoundingBoxDtoToViewerBbox,
			getDocumentFieldIndex,
			onViewerAreaSelected,
			changeTableData,
			validateDocument,
			onFocusTableCell,
			focusLastHeadField,
			allTableRefCells,
			downloadAttachment,
			initDialogOptions,
			showDialog,
			extractDocument,
			initToolbarOptions,
			executeCustomValidationAction,
			setToolbarOptionsInReadOnlyMode,
			toggleToolBarOptions,
			showToolbarButtonText,
			setReadOnlyMode,
			triggerShowDragDialog,
			lockDocument,
			unlockDocument,
			onUnload,
			getAllDocumentClasses,
			getCustomActions,
			onCustomActionShortcut,
			onKeydown,
			validateAndExportDocument,
			deleteDocument,
			suspendDocument,
			afterSendSuccess,
			saveNewDocumentClass,
			onChangeDocumentClassChangeForm,
			onClickDocumentReadOnly,
			enterDocumentGroup,
			onHoverItemAutocomplete,
			setActiveTable,
			getUserAuth,
			getDocumentFieldValue,
			setupViewer,
			sortFieldsByOrder,
			getDocumentClassTrainingKey,
			fieldLayoutByRows,
			checkFieldsInGroup,
			setColSize,
			setColSizeOfCurrentField,
			checkSameLineFieldsInGroup,
			getDocumentClassFieldGroupLayouts,
			focusOnFirstField,
			allHeadRefFields,
			reloadData,
			unlockViewedDocument,
			onRouteChange,
		};
	},
});

