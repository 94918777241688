import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-553dc5e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container p-p-2" }
const _hoisted_2 = { class: "header p-d-none p-d-md-flex" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QueueTimeline = _resolveComponent("QueueTimeline")!
  const _component_DocumentList = _resolveComponent("DocumentList")!
  const _component_QueueEntryList = _resolveComponent("QueueEntryList")!
  const _component_SearchForm = _resolveComponent("SearchForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_QueueTimeline)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.usingQueueEntryFallback)
          ? (_openBlock(), _createBlock(_component_DocumentList, {
              key: 0,
              documents: _ctx.documents,
              documentClassId: _ctx.documentClassId,
              documentClassName: _ctx.documentClassName,
              fieldColumns: _ctx.fieldColumns,
              filterOptions: _ctx.filters,
              paginationInfo: _ctx.paginationOption,
              onOnRowSelect: _ctx.onRowSelectDocumentList,
              onOnReload: _ctx.reloadData,
              onOnPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
              onOnFilter: _ctx.onFilter,
              onOnSort: _ctx.onSort,
              onOpenSaveSearch: _ctx.openSaveSearch,
              onOpenSelectSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSelectSearch = true)),
              onClearSearch: _ctx.clearSearch,
              showSelectSearch: _ctx.allSearches.length > 0,
              loaded: _ctx.loaded,
              fullText: _ctx.fullText,
              "onUpdate:fullText": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fullText) = $event))
            }, null, 8, ["documents", "documentClassId", "documentClassName", "fieldColumns", "filterOptions", "paginationInfo", "onOnRowSelect", "onOnReload", "onOnFilter", "onOnSort", "onOpenSaveSearch", "onClearSearch", "showSelectSearch", "loaded", "fullText"]))
          : (_openBlock(), _createBlock(_component_QueueEntryList, {
              key: 1,
              queueEntries: _ctx.queueEntries,
              documentClassId: _ctx.documentClassId,
              documentClassName: _ctx.documentClassName,
              fieldColumns: _ctx.fieldColumns,
              paginationInfo: _ctx.paginationOption,
              loaded: _ctx.loaded,
              onOnRowSelect: _ctx.onRowSelectQueueEntryList,
              onOnReload: _ctx.reloadData,
              onOnPage: _ctx.onPage
            }, null, 8, ["queueEntries", "documentClassId", "documentClassName", "fieldColumns", "paginationInfo", "loaded", "onOnRowSelect", "onOnReload", "onOnPage"]))
      ])
    ]),
    _createVNode(_component_EntryDialog, {
      visible: _ctx.showSaveSearch,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showSaveSearch) = $event)),
      style: {width: '50rem'},
      header: _ctx.$t('Squeeze.General.SaveSearchList'),
      modal: true,
      onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSaveSearch = false, _ctx.showErrorMessage= false, _ctx.isInvalid = true)),
      onOnConfirm: _ctx.saveSearch,
      saveButtonText: _ctx.searchSave.actionType === 'delete' ? _ctx.$t('Squeeze.General.Delete') : undefined,
      showKeepDialogOpen: _ctx.searchSave.actionType === 'delete',
      keepDialogOpenText: _ctx.$t('Squeeze.General.FurtherDelete'),
      loading: _ctx.loading
    }, {
      content: _withCtx(() => [
        _createVNode(_component_SearchForm, {
          showErrorMessage: _ctx.showErrorMessage,
          search: _ctx.searchSave,
          allSearches: _ctx.allSearches,
          searchId: _ctx.searchId,
          onUpdate: _ctx.onUpdate
        }, null, 8, ["showErrorMessage", "search", "allSearches", "searchId", "onUpdate"])
      ]),
      _: 1
    }, 8, ["visible", "header", "onOnConfirm", "saveButtonText", "showKeepDialogOpen", "keepDialogOpenText", "loading"]),
    _createVNode(_component_EntryDialog, {
      visible: _ctx.showSelectSearch,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showSelectSearch) = $event)),
      style: {width: '50rem'},
      header: _ctx.$t('Squeeze.General.SelectSearch'),
      modal: true,
      onOnClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showSelectSearch = false)),
      onOnConfirm: _ctx.triggerSearch,
      saveButtonText: _ctx.$t('Squeeze.General.Execute'),
      loading: _ctx.loading
    }, {
      content: _withCtx(() => [
        _createVNode(_component_SearchForm, {
          showErrorMessage: _ctx.showErrorMessage,
          search: _ctx.searchSave,
          allSearches: _ctx.allSearches,
          searchId: _ctx.searchId,
          showSearchesOnly: true,
          onUpdate: _ctx.onUpdate,
          onSearchSelected: _ctx.onSearchSelect
        }, null, 8, ["showErrorMessage", "search", "allSearches", "searchId", "onUpdate", "onSearchSelected"])
      ]),
      _: 1
    }, 8, ["visible", "header", "onOnConfirm", "saveButtonText", "loading"])
  ], 64))
}